import { useCallback, useReducer, useState } from "react";

export const useOnPressAppIcons = () => {
  const [alertData, setAlertData] = useState({
    title: "Copied to clipboard!",
    message: "Please paste into your email or text message.",
  });

  const [isAlertOpen, toggleIsAlertOpen] = useReducer(
    (isVisible) => !isVisible,
    false
  );

  const onPressCopy = useCallback(async ({ text }) => {
    if (text) {
      navigator.clipboard.writeText(text).then(
        function () {
          console.log("Async: Copying to clipboard was successful!");
        },
        function () {
          console.error("Async: Could not copy text: ");
        }
      );
    }
  }, []);

  const onPressWhatsappButton = () => {
    // Open a message to be sent to Ryan's WhatsApp number
    const baseUrl = "https://api.whatsapp.com";
    const phoneNumber = "+17869097910";
    const stringToSend = "Hey, Ryan!";
    const whatsappUrl = `${baseUrl}/send?phone=${phoneNumber}&text=${encodeURIComponent(
      stringToSend
    )}`;
    window.open(whatsappUrl, "_blank");
  };

  const onPressAppIcons = useCallback(
    async ({ type }) => {
      switch (type) {
        case "email":
          onPressCopy({ text: "Ryan.Zernach@Archlife.org" });
          setAlertData({
            message: "Ryan.Zernach@Archlife.org",
            title: "Copied to clipboard!",
          });
          toggleIsAlertOpen();
          break;
        case "phone":
          onPressCopy({ text: "470-253-4592" });
          setAlertData({
            message: "470-253-4592",
            title: "Copied to clipboard!",
          });
          toggleIsAlertOpen();
          break;
        case "messages":
          // insert whatsapp logic to send message to me in whatsapp
          onPressWhatsappButton();
          // onPressCopy({ text: "470-253-4592" });
          // setAlertData({
          //   message: "470-253-4592",
          //   title: "Copied to clipboard!",
          // });
          // toggleIsAlertOpen();
          break;
        default:
          break;
      }
    },
    [onPressCopy]
  );

  return {
    onPressAppIcons,
    isAlertOpen,
    toggleIsAlertOpen,
    alertData,
  };
};
