

import { useOnPressCopy } from "../../scripts/use-on-press-copy/use-on-press-copy"
import { WebContext } from "../../contexts/web/web-context-provider"
import { colors } from '../../assets/constants/colors'
import React, { useContext, useState } from "react"
import { Navbar } from "../navbar/navbar"
import { Button } from "../button/button"
import { FiCopy, FiMail } from "react-icons/fi"
import { ImCheckmark } from 'react-icons/im'
import '../../App.css'

/**
 * Footer with contact information (to be shown on all screens)
 */
export const Footer = () => {

    const {
        onPressOpenLinkInNewTab,
        onPressOpenLink
    } = useContext(WebContext)

    const {
        onPressCopy: onPressCopyEmail,
    } = useOnPressCopy({ text: 'hello@archlife.org' })

    const [hasBeenCopied, sethasBeenCopied] = useState(false)

    const onPressEmailButton = () => {
        onPressCopyEmail()
        sethasBeenCopied(true)
        setTimeout(() => {
            sethasBeenCopied(false)
        }, 1700)
    }

    return (
        <footer className="vshapedTopDivider">
            <div style={{ backgroundColor: '#00000099', paddingTop: '20px', paddingBottom: '20px' }}>
                <div className="centerEverything" style={{ paddingTop: 2, paddingBottom: 5 }}>
                    <Navbar />
                    {/* <div className="centerEverything" style={{ width: '68%', height: 1, backgroundColor: '#ffffff50', marginLeft: '16%', marginTop: 0, marginBottom: 10 }} /> */}
                    {/* <div className="flexed" style={{ justifyContent: 'center', paddingTop: 10, paddingBottom: 5 }}>
                        {[
                            {
                                name: 'LinkedIn',
                                path: 'https://www.linkedin.com/in/zernach/',
                                class: "maskedLinkedIn",
                                tintColor: colors.blue
                            },
                            {
                                name: 'GitHub',
                                path: 'https://github.com/zernach/',
                                class: "maskedGithub",
                                tintColor: colors.yellow,
                            },
                            {
                                name: 'Twitter',
                                path: 'https://twitter.com/zernach/',
                                class: "maskedTwitter",
                                tintColor: colors.green,
                            },
                            {
                                name: 'Spotify',
                                path: 'https://open.spotify.com/playlist/4skyRST9UzDsL8QRrbgC80?si=SerVNTzXR3u4sP1T8y6k_Q/',
                                class: "maskedSpotify",
                                tintColor: `${colors.green}60`,
                            },
                            {
                                name: 'YouTube',
                                path: 'https://www.youtube.com/channel/UCTcyHgRop949pvRCqdwnQXw/',
                                class: "maskedYoutube",
                                tintColor: colors.pink,
                            },
                            {
                                name: 'Instagram',
                                path: 'https://www.instagram.com/zernach/',
                                class: "maskedInstagram",
                                tintColor: `${colors.beach}99`,
                            },
                            {
                                name: 'Facebook',
                                path: 'https://www.facebook.com/zernach/',
                                class: "maskedFacebook",
                                tintColor: `${colors.white}99`,
                            },
                            {
                                name: 'Pinterest',
                                path: 'https://www.pinterest.com/zernach/',
                                class: "maskedPinterest",
                                tintColor: `${colors.white}60`,
                            }
                        ].map((item, index) => (
                            <div key={`index${index}`} onClick={() => onPressOpenLinkInNewTab({ link: item?.path })} className={item.class} alt={item.name} style={{ backgroundColor: item.tintColor, marginLeft: 7, marginRight: 7, cursor: 'pointer' }} />
                        ))}
                    </div> */}
                    {/* <h1 style={{ color: '#ffffff99', marginTop: 6 }}>
                        {'@Zernach'}
                    </h1> */}
                    {/* <img src={require('../../assets/images/other/zernach.png')} className="zernach-icon" alt="zernach-icon" style={{}} /> */}
                    <div style={{ marginTop: 5 }}>
                        <img onClick={() => onPressOpenLinkInNewTab({ link: 'https://stemtechnetwork.com' })} src={require('../../assets/images/other/stem-icons.png')} className="stem-icons" alt="stem-icons" style={{ marginBottom: 10, cursor: 'pointer' }} />
                    </div>
                    <div style={{ height: 200, width: 100 }} />

                    <div style={{ marginBottom: 20 }}>
                        <Button
                            rightIcon={hasBeenCopied ? <ImCheckmark style={{ marginTop: -5 }} /> : <FiCopy />}
                            leftIcon={hasBeenCopied ? null : <FiMail />}
                            onPress={onPressEmailButton}
                            renderText={hasBeenCopied ?
                                <span>{'COPIED!'}</span>
                                :
                                <span style={{ fontFamily: 'regular' }}>{'HELLO'}<span style={{ fontFamily: 'thin' }}>{'@ARCHLIFE.ORG'}</span></span>
                            }
                            style={{
                                backgroundColor: hasBeenCopied ? `${colors.primary}` : `${colors.beach}25`,
                                opacity: hasBeenCopied ? 1 : undefined,
                                marginRight: 4,
                                marginLeft: 4,
                                marginTop: 8,
                                marginBottom: 15
                            }}
                            textStyle={{
                                color: hasBeenCopied ? colors.archDark : undefined,
                            }}
                        />
                        {/* <Button
                            onPress={() => onPressOpenLink({ link: '/schedule' })}
                            text={'Schedule Conversation'}
                            style={{ backgroundColor: `${colors.beach}40`, marginLeft: 4, marginRight: 4, marginBottom: 7 }}
                        /> */}
                    </div>
                    <div onClick={() => onPressOpenLink({ link: '/portfolio/miami-beach-attractions' })} style={{ cursor: 'pointer' }}>
                        <div style={{ marginTop: 5 }}>
                            <img src={require('../../assets/images/backgrounds/miamibeach-logo.png')} className="stem-icons" alt="stem-icons" style={{ marginBottom: 10, cursor: 'pointer', width: '200px', height: '100%' }} />
                        </div>
                        <h5 className={'footerNotes'} style={{ color: '#ffffff99', marginBottom: 2, fontFamily: 'bold', fontSize: 'medium' }}>
                            {'All Rights Reserved © 2023'}
                        </h5>
                        <h5 className={'footerNotes'} style={{ color: '#ffffff99', marginTop: 4, marginBottom: 2, fontFamily: 'bold', fontSize: 'medium' }}>
                            {'ARCHLIFE LLC'}
                        </h5>
                    </div>
                </div>
            </div>
        </footer>
    )
}
