import React, { useMemo } from 'react'
import { colors } from '../../assets/constants/colors';
import { BsGithub, BsCode, BsBoxArrowInUpRight } from 'react-icons/bs';
import { VscNotebook } from 'react-icons/vsc';
import { GiTiedScroll } from 'react-icons/gi';
import { MdOutlineDataset } from 'react-icons/md';

export const useBlogPosts = () => {

    const blogPosts = useMemo(() => {
        return [
            // AIRLINE PRICE PREDICTIONS
            {
                route: 'airline-price-predictor-how-are-flight-prices-calculated',
                title: '🛩️ Airline Price Predictor: How are Flight Prices Calculated?',
                subtitle: 'How much will you pay for your next flight? I trained a machine learning model using 9 million flight records to predict the price of your next flight!',
                image: require('../../assets/images/portfolio/posts/Airline-Price-Predictions-Flights-Zernach-Machine-learning-algorithms-smart-ai-engineer-deploy-educated.com-The-Zernach-Foundation-Ryan-Zernach.png'),
                highlightColor: colors.blue,
                introduction: {
                    summary: 'Select your origin city, your destination city, with which airline company you are flying, and how many tickets you are purchasing — and my model will predict the round-trip cost! View the ReadMe in Github to learn about my modeling process & statistical analysis.',
                    techStack: 'Flask, Heroku, Dash Core Components, Scikit-Learn, Category_Encoders, PDP (Partial Dependence Plots), Plotly, Matplotlib, Seaborn, Shap (Shapley), eli5 (Permutation Importances)',
                    teamAndTime: 'Solo Project; Less than Two Weeks',
                    personalContributions: undefined,
                },
                links: [
                    {
                        title: 'GitHub Repository',
                        url: 'https://github.com/Zernach/Airline-Price-Predictions',
                        icon: <BsGithub />
                    },
                    {
                        title: 'Modeling Notebook',
                        url: 'https://colab.research.google.com/drive/1s3SJs2dpnH2LQvR9S3JNH2C-yD1na_4R',
                        icon: <VscNotebook />
                    },
                    {
                        title: 'View Dataset',
                        url: 'https://www.kaggle.com/zernach/2018-airplane-flights',
                        icon: <MdOutlineDataset />
                    }
                ]
            },
            // BETTERREADS
            {
                route: 'readrr-a-platform-to-discover-track-and-share-new-books',
                title: '📚 BetterReads: A Platform to Discover, Track, and Share New Books',
                subtitle: 'Machine Learning Combined with Mobile App Development',
                video: 'https://www.youtube.com/embed/yOSn0YBKOkQ',
                image: require('../../assets/images/portfolio/posts/BetterReads_Book_Recommendation_Engine_Ryan_Zernach.png'),
                highlightColor: colors.yellow,
                introduction: {
                    summary: `BetterReads is an enhanced recreation of Amazon's GoodReads. The user is able to create an account, select which genres of books they find appealing, adjust their profile, find recommendations based on a wide variety of interchangeable factors, and more! This was an extremely fun and successful group project that users can actually enjoy!`,
                    techStack: 'AWS RDS Database, AWS Elastic Beanstalk, Docker Containerization, GoogleBooks API, Flask, FastAPI, Scikit-Learn, KNearestNeighbors, One-Hot Encoder, Natural Language Processing, JSONify, Numpy, word2vec',
                    teamAndTime: 'Four Data Scientists, Three Web Developers, Two iOS Developers, and One UI/UX Designer, Three months',
                    personalContributions: [
                        `Iteratively developed/improved Natural Language Processing (NLP) predictive models to recommend books to user`,
                        `Developed a Collaborative Filtering model based on user ratings (see Data Science presentation video below for more information)`,
                        `Facilitated the process of surveying users to determine how to improve our model — learned how to automate this process`,
                        `Created back-end Python API's with Flask on AWS's Elastic Beanstalk to facilitate JSON post requests for the web/iOS developers`,
                        `Launched a PostgreSQL instance on AWS RDS to store millions of GoogleBooks data, most of which was manually scraped using a multi-threading approach`,
                        `Collaborated on a remote team of data scientists, iOS developers, web developers, and a UI/UX designer to produce BetterReads`
                    ],
                },
                links: [
                    {
                        title: 'Data Science Github Repository',
                        url: 'https://github.com/Lambda-School-Labs/betterreads-ds',
                        icon: <BsGithub />
                    },
                    {
                        title: 'Weekly Journal Entries',
                        url: 'https://drive.google.com/drive/folders/13KZYvx6NVR37v_rKUfp4lZCxiEcQBpNU?usp=sharing',
                        icon: <VscNotebook />
                    },
                    {
                        title: 'Slide Deck Presentation',
                        url: 'https://docs.google.com/presentation/d/1kom-d5q6-xCe6ujcYvzZT6LTieG_5h0POvsihaoDgfM/edit?usp=sharing',
                        icon: <MdOutlineDataset />
                    }
                ],
                videosTitle: `Weekly Recorded Meetings with Software's Stakeholder`,
                videos: [
                    {
                        title: '04/07/2020',
                        url: 'https://www.youtube.com/watch?v=wZhitNlEMTo'
                    },
                    {
                        title: '04/16/2020',
                        url: 'https://www.youtube.com/watch?v=UDX77CeCElE'
                    },
                    {
                        title: '04/24/2020',
                        url: 'https://www.youtube.com/watch?v=Gvoz0AgkkKo'
                    },
                    {
                        title: '04/30/2020',
                        url: 'https://www.youtube.com/watch?v=OENjphEIqjQ'
                    },
                    {
                        title: '05/08/2020',
                        url: 'https://www.youtube.com/watch?v=BYb541j8yxY'
                    },
                    {
                        title: '05/14/2020',
                        url: 'https://www.youtube.com/watch?v=OO4uLWFiF18'
                    },
                    {
                        title: '05/21/2020',
                        url: 'https://www.youtube.com/watch?v=1bmqBar70Xs'
                    },
                    {
                        title: '05/28/2020',
                        url: 'https://www.youtube.com/watch?v=6x1ZgnnHIBI'
                    },
                ]
            },
            // BRAINPOWER
            {
                route: 'brainpower-the-future-of-communicating-with-technology',
                title: '🧠 BrainPower: The Future of Communicating with Technology',
                subtitle: 'Control a Virtual Light Switch & Thermostat with your Brain',
                video: "https://www.youtube.com/embed/8JTfn3CZbts",
                image: require('../../assets/images/portfolio/posts/Ryan_Zernach_Brain_Controlled_Web_Application_Zernach_Neurotech_Archlife_Industries.png'),
                highlightColor: colors.pink,
                introduction: {
                    summary: `The user is able to create an account, connect their EEG device, and control a digitally-animated light switch and thermostat with their brain's neuroelectrical signals/wavelengths.`,
                    techStack: 'Brainflow, Scikit-Learn, Flask, Heroku, React JS, HTML',
                    teamAndTime: 'One Data Scientist (Myself), One Front-End Developer (Ellen Weng), One Back-End Developer (Roenz Aberin), and an EEG Device User (Milecia McGregor) — Two Weeks',
                    personalContributions: [
                        `Trained machine learning algorithm to predict what the user is thinking`,
                        `Built a back-end RESTful Python API's to return the predicted command: up, down, left, right, yes, or no.`
                    ],
                },
                links: [
                    {
                        title: 'Back-End Python Repo',
                        url: 'https://github.com/archlife-industries/archlifecommands',
                        icon: <BsGithub />
                    },
                    {
                        title: 'Front-End G.U.I. CODE',
                        url: 'https://github.com/archlife-industries/eeg-control-proof-of-concept',
                        icon: <BsGithub />
                    },
                    {
                        title: 'Data Exploration',
                        url: 'https://colab.research.google.com/drive/1EkwECh3OjLIfOIfhIe703Zlb86i2az1A?usp=sharing',
                        icon: <VscNotebook />
                    },
                ],
                body: [
                    {
                        image: require('../../assets/images/portfolio/posts/EEG_record_brain_signal_button_archlife_industries.gif'),
                    },
                    {
                        text: `MVP/Proof-of-Concept Journal Updates, March 13th 2020 —`
                    },
                    {
                        text: `Milecia recorded our EEG data using her OpenBCI device, which has four electrodes. She recorded (100) 1-second bursts of EEG data, which Ryan used to train a predictive model with 97% accuracy! We built a back-end Python API — which, when called upon, will return a number 0, 1, 2, 3, 4, or 5. Those numbers correspond to one of six commands that the user may be thinking: no, yes, up, down, left, or right.`
                    },
                    {
                        text: `Ellen & Roenz built both the front-end React JS user-interface and the back-end connectivity to our database for securely storing user's data. The back-end Python API is called upon when the user on the front-end clicks the record EEG button, which is pictured at the bottom of this page with the pink brain and rotating blue/white circles. When that button is clicked, the following code is executed:`
                    },
                    {
                        text: `⚙️ Connect to the local EEG device`
                    },
                    {
                        text: `⚙️ Collect EEG data for one second`
                    },
                    {
                        text: `⚙️ Compile the data into a pandas dataframe`
                    },
                    {
                        text: `⚙️ Run predictions on those instances of EEG “screenshots”`
                    },
                    {
                        text: `⚙️ Of those predictions, return the command that was most frequently predicted`
                    },
                    {
                        text: `Then with that returned command, the front-end is hard-coded to react in a certain way, depending on which command was being “thought of” by the user wearing the EEG device for that one second in time — sort of like recording your voice to make a voice command to Siri or Google Assistant, but you're recording your brain's neuroelectrical transmissions.`
                    },
                    {
                        text: `Then with that returned command, the front-end is hard-coded to react in a certain way, depending on which command was being “thought of” by the user wearing the EEG device for that one second in time — sort of like recording your voice to make a voice command to Siri or Google Assistant, but you're recording your brain's neuroelectrical transmissions.`
                    },
                    {
                        text: `We'd eventually like to be able to just continuously record and make predictions in real-time, while filtering out all other thoughts that the user is thinking, unless they're thinking of a specific command: no, yes, up, down, left, right. And we'd also like to eventually, obviously add more commands to our list of available actions.`
                    },
                    {
                        text: `However, these six commands are enough for a proof-of-concept. Currently, our front-end is equipped with the following functionalities, using just these six commands.`
                    },
                ]
            },
            // GLACIERS
            {
                route: 'global-glaciers-where-on-earth-are-they-located',
                title: '🧊 Global Glaciers: Where on Earth are They Located?',
                subtitle: '',
                image: require('../../assets/images/portfolio/posts/Where_on_earth_all_the_worlds_largest_glaciers_at_what_elevation.png'),
                highlightColor: colors.blue,
                introduction: {
                    summary: `Where are the largest glaciers located on Earth? At what elevation are the globe's glaciers? I used a combination of a mapping library and a GIF-creator library to showcase the answers to these two questions!`,
                    techStack: 'Basemap (Mapping Library), imageio (GIF creator library), Numpy, Pandas, Matplotlib, Seaborn, Photoshop, Itertools, Libgeo',
                    teamAndTime: 'Solo Project, Five Days',
                    personalContributions: undefined,
                },
                links: [
                    {
                        title: 'Jupyter Notebook (Google Colab)',
                        url: 'https://colab.research.google.com/drive/17259wEFS5IYM2zUdJqHPZtRt6uOgW8ws',
                        icon: <VscNotebook />
                    }
                ],
                body: [
                    {
                        text: `If you thought Antarctica was the world's largest glacier, then guess again! You may be surprised to learn that Antarctica isn't even a glacier in the first place: it's an ice sheet — and so is Greenland. So what's the difference between a glacier and an ice sheet?`
                    },
                    {
                        image: require('../../assets/images/portfolio/posts/difference-between-ice-shelf-and-glacier-research-uc-davis.png'),
                        caption: `NOTE: Illustration Courtesy of University of California`
                    },
                    {
                        text: `As explained in the illustration above, a glacier resides on land and an ice sheet floats atop the ocean's surface (just like an ice cube floats in your favorite beverage). And an iceburg? Well, those are just miniaturized ice sheets. So where across Earth's landmasses are the actual glaciers located? I have yet to see one in person!`
                    },
                    {
                        image: require('../../assets/images/portfolio/posts/Where_are_the_worlds_largest_of_the_globes_glaciers_locations-2-1.gif'),
                        caption: `NOTE: These are the locations of 130,000+ glaciers. Therefore, the top 1% are the locations of ~1,300 (pink) glaciers and the other 99% are the locations of ~128,700 (blue) glaciers.`
                    },
                    {
                        text: `Prior to analysis, I never imagined glaciers existing anywhere except perhaps the north and south poles — the Arctic and the Antarctic. What other glacial factors may affect where a glacier resides on Earth?`
                    },
                    {
                        image: require('../../assets/images/portfolio/posts/At_what_elevation_are_the_globes_earths_glaciers_located_maps.gif'),
                        caption: `NOTE: Of the 130,000+ glaciers within this dataset, ~30% are (green) glaciers between 45°S-45°N, and ~70% are (yellow) glaciers that are either below 45°S or above 45°N.`
                    },
                    {
                        text: `Okay, great! The data has been split into green glaciers and yellow glaciers. Let's take a look at the overall altitudes of these two subsets of data:`
                    },
                    {
                        image: require('../../assets/images/portfolio/posts/At-what-elevation-level-are-the-globes-earths-glaciers-bar-chart-height-altitude.png'),
                        caption: `NOTE: The average elevation of the green subset of data is nearly two-and-a-half times the average elevation of the yellow subset of data.`
                    },
                    {
                        text: `To conclude, if you ever plan to go explore Earth's natural, frozen beauties, then be prepared to trek to the most northern reaches, the most southern stretches, or the highest of altitudes — regardless, dress warmly!`
                    },
                ]
            },
            // DATA VISUALIZATIONS
            {
                route: 'varieties-types-data-visualizations-charts-plots-graphs',
                title: '📊 Data Visualizations, Charts, and Graphs',
                subtitle: `Using Python to Visualize Data`,
                image: require('../../assets/images/portfolio/posts/Data-Visualizations-Charts-Graphs-Ryan-Zernach-python-javascript-html.png'),
                highlightColor: colors.green,
                imagesBackgroundColor: '#c1c1c1',
                introduction: {
                    summary: `Here are some of my favorite data visualizations that I've created using Python. The code to reproduce each of my visualizations is included as a link for each one.`,
                },
                body: [
                    {
                        accordions: [
                            {
                                title: 'Bar Charts',
                                content: [
                                    {
                                        image: require('../../assets/images/portfolio/posts/dataviz/bar/SLEEPING-APART-How-often-do-partners-spouses-sleep-together-versus-apart-seperately.png'),
                                        caption: 'Most People Always Sleep in the Same Bed as their Partner',
                                        spacer: 60,
                                        button: {
                                            title: 'Code for this Visualization',
                                            url: 'https://colab.research.google.com/drive/1TEKyJsFmuIyxHWn1N6udvucPlr4dD73_?usp=sharing',
                                            icon: <BsCode />,
                                            style: { marginTop: 10 }
                                        }
                                    },
                                    {
                                        image: require('../../assets/images/portfolio/posts/dataviz/bar/Thruth-to-Power-is-Divisive-IMDB-Ratings-Bar-Chart-Votes.png'),
                                        caption: `This Ratings for this Movie are Divisive: Most People Rated it either a 1 or a 10`,
                                        spacer: 60,
                                        button: {
                                            title: 'Code for this Visualization',
                                            url: 'https://colab.research.google.com/drive/1TEKyJsFmuIyxHWn1N6udvucPlr4dD73_?usp=sharing',
                                            icon: <BsCode />,
                                            style: { marginTop: 10 }
                                        }
                                    },
                                    {
                                        image: require('../../assets/images/portfolio/posts/dataviz/bar/Predicted-Probably-Chart-with-Threshold-Line.png'),
                                        caption: 'Probability Distribution for Dataset Used in Repairing Tanzanian Water-Pumps ',
                                        spacer: 60,
                                        button: {
                                            title: 'Code for this Visualization',
                                            url: 'https://colab.research.google.com/drive/109xVg0AdSWYsYm8qeyXFZ73Pw0j6iAoq?usp=sharing',
                                            icon: <BsCode />,
                                            style: { marginTop: 10 }
                                        }
                                    },
                                    {
                                        image: require('../../assets/images/portfolio/posts/dataviz/bar/what-influences-has-effect-on-the-cost-price-of-plane-tickets.png'),
                                        caption: 'The Number of Miles Traveled Has the Greatest Impact on the Price of a Plane Ticket, followed by the Airline Company with which you Fly',
                                        spacer: 60,
                                        button: {
                                            title: 'Code for this Visualization',
                                            url: 'https://colab.research.google.com/drive/1s3SJs2dpnH2LQvR9S3JNH2C-yD1na_4R',
                                            icon: <BsCode />,
                                            style: { marginTop: 10 }
                                        }
                                    },
                                    {
                                        image: require('../../assets/images/portfolio/posts/dataviz/bar/Coral-Reef-Bleaching-Severity-Distribution-Over-Time5.png'),
                                        caption: 'A Stacked Histogram Bar Chart Shows the Spread of Bleached Coral Reef Sites from 1998-2010',
                                        spacer: 60,
                                        button: {
                                            title: 'Code for this Visualization',
                                            url: 'https://colab.research.google.com/drive/1sU0WVwi2f5HsxTdLqxgrAwPtLFk9FPEo?usp=sharing',
                                            icon: <BsCode />,
                                            style: { marginTop: 10 }
                                        }
                                    },
                                    {
                                        image: require('../../assets/images/portfolio/posts/dataviz/bar/Feature-Importances-for-FreeThrow-Jump-Shots-Accuracy-Ryan-Zernach.png'),
                                        caption: 'The Type of “Action Type” that the Basketball Player Uses When the Ball Is Shot has the Greatest Positive Impact on the Accuracy of Basketball Shots Taken',
                                        spacer: 60,
                                        button: {
                                            title: 'Code for this Visualization',
                                            url: 'https://colab.research.google.com/drive/1ZQupD2igT_rh9MpsNXQx9ON3XErnyCoD?usp=sharing',
                                            icon: <BsCode />,
                                            style: { marginTop: 10 }
                                        }
                                    },
                                ]
                            },
                            {
                                title: 'Line Charts',
                                content: [
                                    {
                                        image: require('../../assets/images/portfolio/posts/dataviz/line/Coral-Reef-Bleaching-Severity-Distribution-Over-Time.png'),
                                        caption: '',
                                        spacer: 60,
                                        button: {
                                            title: 'Code for this Visualization',
                                            url: 'https://colab.research.google.com/drive/1sU0WVwi2f5HsxTdLqxgrAwPtLFk9FPEo?usp=sharing',
                                            icon: <BsCode />,
                                            style: { marginTop: 10 }
                                        }
                                    },
                                    {
                                        image: require('../../assets/images/portfolio/posts/dataviz/line/Coral-Reef-Bleaching-Severity-Distribution-Over-Time2.png'),
                                        caption: '',
                                        spacer: 60,
                                        button: {
                                            title: 'Code for this Visualization',
                                            url: 'https://colab.research.google.com/drive/1sU0WVwi2f5HsxTdLqxgrAwPtLFk9FPEo?usp=sharing',
                                            icon: <BsCode />,
                                            style: { marginTop: 10 }
                                        }
                                    },
                                    {
                                        image: require('../../assets/images/portfolio/posts/dataviz/line/how-many-plane-tickets-are-purchased-at-a-time.png'),
                                        caption: 'When A Flight Ticket is Purchased, Most People Only Purchase A Single Ticket',
                                        spacer: 60,
                                        button: {
                                            title: 'Code for this Visualization',
                                            url: 'https://colab.research.google.com/drive/1s3SJs2dpnH2LQvR9S3JNH2C-yD1na_4R',
                                            icon: <BsCode />,
                                            style: { marginTop: 10 }
                                        }
                                    },
                                    {
                                        image: require('../../assets/images/portfolio/posts/dataviz/line/Partial-Dependence-Plots-PDPs-show-how-a-feature-approximately-affects-target-variable-feature-is-adjusted-Ryan-Zernach-Zernach.com-Airline-Flight-Price-Predictions.gif'),
                                        caption: 'Two Different Types of Partial Dependence Plots (GIF image)',
                                        spacer: 60,
                                        button: {
                                            title: 'Code for this Visualization',
                                            url: 'https://colab.research.google.com/drive/1s3SJs2dpnH2LQvR9S3JNH2C-yD1na_4R',
                                            icon: <BsCode />,
                                            style: { marginTop: 10 }
                                        }
                                    },
                                    {
                                        image: require('../../assets/images/portfolio/posts/dataviz/line/Elbow-method-for-determining-Optimal-X-value-for-k-means.png'),
                                        caption: 'The Optimal K-Means Value Lies within the Elbow, so at either 3 or 4 for this Particular Dataset',
                                        spacer: 60,
                                        button: {
                                            title: 'Code for this Visualization',
                                            url: 'https://colab.research.google.com/drive/1O0In6Ssdzs-kwobHfHtRQIdnvBAnOpr3?usp=sharing',
                                            icon: <BsCode />,
                                            style: { marginTop: 10 }
                                        }
                                    },
                                    {
                                        image: require('../../assets/images/portfolio/posts/dataviz/line/distribution-chart-graph-of-price-per-ticket-plane-flight.png'),
                                        caption: 'The Average Cost of a USA Domestic Plane Ticket in 2018 was ~$180',
                                        spacer: 60,
                                        button: {
                                            title: 'Code for this Visualization',
                                            url: 'https://colab.research.google.com/drive/1s3SJs2dpnH2LQvR9S3JNH2C-yD1na_4R',
                                            icon: <BsCode />,
                                            style: { marginTop: 10 }
                                        }
                                    },
                                ]
                            },
                            {
                                title: 'Scatter/Cluster Plots',
                                content: [
                                    {
                                        image: require('../../assets/images/portfolio/posts/dataviz/scatter/At-what-elevation-level-are-the-globes-earths-glaciers-4.png'),
                                        caption: `The Size of Earth's Glaciers is Largely Dependent on its Elevation Level`,
                                        spacer: 60,
                                        button: {
                                            title: 'Code for this Visualization',
                                            url: 'https://colab.research.google.com/drive/17259wEFS5IYM2zUdJqHPZtRt6uOgW8ws?usp=sharing',
                                            icon: <BsCode />,
                                            style: { marginTop: 10 }
                                        }
                                    },
                                    {
                                        image: require('../../assets/images/portfolio/posts/dataviz/scatter/Visualization-Data-KMeans.png'),
                                        caption: '',
                                        spacer: 60,
                                        button: {
                                            title: 'Code for this Visualization',
                                            url: 'https://colab.research.google.com/drive/1O0In6Ssdzs-kwobHfHtRQIdnvBAnOpr3?usp=sharing',
                                            icon: <BsCode />,
                                            style: { marginTop: 10 }
                                        }
                                    },
                                    {
                                        image: require('../../assets/images/portfolio/posts/dataviz/scatter/n-the-higher-chance-of-it-being-malignant.png'),
                                        caption: '',
                                        spacer: 60,
                                        button: {
                                            title: 'Code for this Visualization',
                                            url: 'https://colab.research.google.com/drive/1s4lS94d7YZq9GndOsX-XCtNgK_y_cMS5?usp=sharing',
                                            icon: <BsCode />,
                                            style: { marginTop: 10 }
                                        }
                                    },
                                ]
                            },
                            {
                                title: 'Heat Maps',
                                content: [
                                    {
                                        image: require('../../assets/images/portfolio/posts/dataviz/heat/Annual-Income-versus-Credit-Score-Heat-Map-Distribution-Chatt-Ryan-Zernach.png'),
                                        caption: '',
                                        spacer: 60,
                                        button: {
                                            title: 'Code for this Visualization',
                                            url: 'https://colab.research.google.com/drive/1A4ArVo51Zp-uDAcbdOsJWBRrvzIplPJK?usp=sharing',
                                            icon: <BsCode />,
                                            style: { marginTop: 10 }
                                        }
                                    },
                                    {
                                        image: require('../../assets/images/portfolio/posts/dataviz/heat/Partial-Dependence-Plot-Airplane-Flight-Prices-DIstance-Traveled-Miles-Rises-Price-Rises-Number-Tickets-Ordered-Rises-Price-Drops-Ryan-Zernach.png'),
                                        caption: '',
                                        spacer: 60,
                                        button: {
                                            title: 'Code for this Visualization',
                                            url: 'https://colab.research.google.com/drive/1s3SJs2dpnH2LQvR9S3JNH2C-yD1na_4R',
                                            icon: <BsCode />,
                                            style: { marginTop: 10 }
                                        }
                                    },
                                    {
                                        image: require('../../assets/images/portfolio/posts/dataviz/heat/Confusion-matrix-Ryan-Zernach-Tanzanian-Water-Pumps.png'),
                                        caption: 'Heat Map Shows the Prediction Distribution for Predicting Whether a Tanzanian Water-Pump Needs to be Repaired',
                                        spacer: 60,
                                        button: {
                                            title: 'Code for this Visualization',
                                            url: 'https://colab.research.google.com/drive/1s4lS94d7YZq9GndOsX-XCtNgK_y_cMS5?usp=sharing',
                                            icon: <BsCode />,
                                            style: { marginTop: 10 }
                                        }
                                    },
                                ]
                            },
                            {
                                title: 'Word Charts',
                                content: [
                                    {
                                        image: require('../../assets/images/portfolio/posts/dataviz/word/Word-Map-Ryan-Zernach-Coffee-Shop-Reviews-NLP2.png'),
                                        caption: 'Before Removing Common Stop-Words on a Coffeehouse Reviews Dataset (Size of Box is Proportional to Frequency of Word)',
                                        spacer: 60,
                                        button: {
                                            title: 'Code for this Visualization',
                                            url: 'https://colab.research.google.com/drive/1rUbGSiANwX28lyUNulcR9wZgVux6l0EP?usp=sharing',
                                            icon: <BsCode />,
                                            style: { marginTop: 10 }
                                        }
                                    },
                                    {
                                        image: require('../../assets/images/portfolio/posts/dataviz/word/Word-Map-Ryan-Zernach-Coffee-Shop-Reviews-NLP.png'),
                                        caption: 'After Removing Common Stop-Words on a Coffeehouse Reviews Dataset (Size of Box is Proportional to Frequency of Word)',
                                        spacer: 60,
                                        button: {
                                            title: 'Code for this Visualization',
                                            url: 'https://colab.research.google.com/drive/1rUbGSiANwX28lyUNulcR9wZgVux6l0EP?usp=sharing',
                                            icon: <BsCode />,
                                            style: { marginTop: 10 }
                                        }
                                    },
                                ]
                            },
                            {
                                title: 'Geographic Maps',
                                content: [
                                    {
                                        image: require('../../assets/images/portfolio/posts/dataviz/geographic/Rotating_Globe_Coral_Bleaching_Spots_cropped.gif'),
                                        caption: 'LOCATIONS OF CORAL REEF SITES (1998-2010) — Color-Coded According to Bleaching Severity — Green = No Bleaching ... Blue = Minimal ... Yellow = Medium ... Pink = High Bleaching',
                                        spacer: 60,
                                        button: {
                                            title: 'Code for this Visualization',
                                            url: 'https://colab.research.google.com/drive/1sU0WVwi2f5HsxTdLqxgrAwPtLFk9FPEo?usp=sharing',
                                            icon: <BsCode />,
                                            style: { marginTop: 10 }
                                        }
                                    },
                                    {
                                        image: require('../../assets/images/portfolio/posts/dataviz/geographic/coral-reef-locations-global-earth-bleaching-intensity.gif'),
                                        caption: 'LOCATIONS OF CORAL REEF SITES (1998-2010) — Color-Coded According to Bleaching Severity — Green = No Bleaching ... Blue = Minimal ... Yellow = Medium ... Pink = High Bleaching',
                                        spacer: 60,
                                        button: {
                                            title: 'Code for this Visualization',
                                            url: 'https://colab.research.google.com/drive/1sU0WVwi2f5HsxTdLqxgrAwPtLFk9FPEo?usp=sharing',
                                            icon: <BsCode />,
                                            style: { marginTop: 10 }
                                        }
                                    },
                                    {
                                        image: require('../../assets/images/portfolio/posts/dataviz/geographic/At_what_elevation_are_the_globes_earths_glaciers_located_maps.gif'),
                                        caption: `LOCATIONS OF EARTH'S GLACIERS (1948-2012)`,
                                        spacer: 60,
                                        button: {
                                            title: 'Code for this Visualization',
                                            url: 'https://colab.research.google.com/drive/17259wEFS5IYM2zUdJqHPZtRt6uOgW8ws?usp=sharing',
                                            icon: <BsCode />,
                                            style: { marginTop: 10 }
                                        }
                                    },
                                    {
                                        image: require('../../assets/images/portfolio/posts/dataviz/geographic/Where_are_the_worlds_largest_of_the_globes_glaciers_locations-2-1.gif'),
                                        caption: `LOCATIONS OF EARTH'S GLACIERS (1948-2012)`,
                                        spacer: 60,
                                        button: {
                                            title: 'Code for this Visualization',
                                            url: 'https://colab.research.google.com/drive/1sU0WVwi2f5HsxTdLqxgrAwPtLFk9FPEo?usp=sharing',
                                            icon: <BsCode />,
                                            style: { marginTop: 10 }
                                        }
                                    },
                                ]
                            },
                            {
                                title: 'Force Plots',
                                content: [
                                    {
                                        image: require('../../assets/images/portfolio/posts/dataviz/force-plots/What_Are_Shapley_Force_Plots_Ryan_Zernach.png'),
                                        caption: '',
                                        spacer: 60,
                                        button: {
                                            title: 'Code for this Visualization',
                                            url: 'https://colab.research.google.com/drive/1s3SJs2dpnH2LQvR9S3JNH2C-yD1na_4R',
                                            icon: <BsCode />,
                                            style: { marginTop: 10 }
                                        }
                                    },
                                ]
                            }
                        ]
                    }
                ]
            },
            // FINEQUITIES
            {
                route: 'finequities',
                title: '📊 Finequities',
                subtitle: 'iOS/Android cross between Robinhood & Facebook — PLEASE SEE VIDEO BELOW',
                image: require('../../assets/images/portfolio/app-icons/finequities_icon_dark.png'),
                video: "https://www.youtube.com/embed/RGgxkCc80MI",
                highlightColor: '#00FFDC',
                introduction: {
                    summary: `Experienced & beginner investors alike can use this app to trade stocks/crypto and to discuss their investments with a like-minded community of other investors. Even cooler, investor portfolios are public by default, allowing other investors to directly copy another investor's portfolio with the click of a few buttons. This app includes a diverse array of data visualizations and a deep cavern of powerful, back-end algorithms to power the front-end with rich, insightful data for our users.`,
                    techStack: 'React Native JS, GraphQL, AWS RDS, AWS Lambda',
                    teamAndTime: `For over four months, I was one of two full-time developers, one project manager, and one UI/UX designer. Unfortunately, our team's private seed funding was depleted, forcing the founder to lay-off everyone on the team.`,
                    personalContributions: [
                        `Built out the copy portfolio system, allowing one investor to directly copy & proportionately invest money into the same stocks/crypto that another investors owns, which required a significant number of algorithms to function.`,
                        `Built the front-end for the Discover page, which shows users trending stocks & trending investors — both of which are based on which stocks & investors are being most-viewed by our users. This page also shows Stock Flavors, which are a curated selection of stocks that are grouped into different tastes/preferences.`,
                        `Built a web dashboard for our team to monitor the speeds at which our functions are executed. This inherently provided our team with quantitive metrics — in other words, how fast is our app loading for our users? Which parts of the app load faster than others? Which areas of the app need load-time improvements?`,
                        `Rebuilt the social network, social profile, posting system, like system, commenting system, followers/following system, etc. The social profile includes a diverse array of data visualizations for the user to easily ingest patterns in data.`,
                        `Created the refer-a-friend screen, allowing users to quickly share a message with their friends via deep-links — which were then routed to WhatsApp, Messenger, and Messages.`,
                        `This project had already been started when I came on the team. When I joined, most of the app was written in JavaScript, instead of TypeScript. For the sake of clarity, legibility, & cleanliness, we converted most of the pre-existing JS files into beautifully-organized TypeScript folders — where we separated the logic from the presentation. Each screen now has its own storybooks file & presets file. We thoroughly documented the code with JSDoc so that it can be transcribed into HTML documentation.`,
                        `When I first started on this project, the UI/UX designer was working on an absolute overhaul of the designs for the app. I tirelessly ensured that each screen was pixel-perfect to the designs given, and that the dimensions & spacing were responsive to different user's phone screen sizes. If I had a question about a design, then I was communicative with our team to ensure that we were capable of rapidly deploy improvements where needed.`,
                        `I learned about CI / CD, unit testing, deploying to TestFlight / Expo, and deploying to our users using over-the-air (OTA) updates instead of forcing users to update through the app store.`
                    ],
                },
                links: [
                    {
                        title: 'Finequities Website',
                        url: 'https://finequities.com/',
                        icon: <BsGithub />
                    },
                ]
            },
            // UTTO
            // {
            //     route: 'utto',
            //     title: '☎️ UTTO — Underground-to-Cloud',
            //     highlightColor: colors.blue,
            // },
            // DEMOSPHERE
            {
                route: 'demosphere',
                title: '⚽️🏐🥍🚣‍♀️ Demosphere: Sports League Management Software',
                subtitle: 'iOS/Android React Native Mobile App for Team Players, Coaches, Parents, & League Administrators',
                image: require('../../assets/images/portfolio/app-icons/demosphere_icon_dark.png'),
                video: 'https://www.youtube.com/embed/67oj8p7spd4',
                highlightColor: colors.blue,
                introduction: {
                    // summary: `I was hired when their sports management app was ugly, disorganized, and had poor reviews. Since then, it has been extremely rewarding to know that I have had such a vastly positive impact on the mobile app's underlying architecture, Demosphere's customers, and the overall user experience of the mobile app. The codebase has never been cleaner, more organized, more efficient, & more reliable. The app is now far more aesthetically pleasing, now makes 50x fewer API calls on average per user, now has the need for 90% fewer loading spinners, now has additional features, was entirely converted from Javascript to Typescript, now includes more jest unit tests, now has a vastly more reliable chat experience, and the Crashlytics data has shown consistent improvements in the overall app stability (fewer app crashes in production).`,
                    time: `March 2022 - May 2023 (15 Months)`,
                    personalContributions: [
                        `Oversaw app’s design, development, testing, and publishing`,
                        `Re-engineered the app’s architecture using CLI file generators, useContexts, and use-context-selectors`,
                        'Implemented strategies to reduce useless re-renders (such as memos, useCallbacks, useMemos, & useSelectors)',
                        `Mentored, assisted, and peer-reviewed a junior React Native team`,
                        `Integrated more reliable group chat features, push notifications, and calendar sync`,
                        `Overhauled the UI/UX, performance, and state management patterns`,
                        `Improved efficiency by reducing loading times and number of GraphQL API calls`,
                        `Re-wrote npm/yarn packages using raw React Native to slim down dependencies`,
                        `Transitioned the entire codebase from Javascript to Typescript`,
                        `Generated reusable hooks, scripts, components, and screens`,
                        `Published updates to the Apple and Google app stores`,
                        `Modernized and maintained newest React Native & Expo versions`,
                        `Evaluated Google Crashlytics to further improve stability & performance`,
                        `Demosphere Mobile App Version 2.0 Release Date: June 2023`
                    ],
                },
                links: [
                    {
                        title: 'Demosphere Website',
                        url: 'https://demosphere.com/',
                        icon: <BsBoxArrowInUpRight />
                    }
                ],
                body: [
                    {
                        image: require('../../assets/images/portfolio/posts/demosphere/demosphere_v2_mobile_app_sports_league_management_2023.png'),
                        caption: 'Demosphere Mobile App Version 2.0 — June 2023',
                    }
                ]
            },
            // ADP GYM
            {
                route: 'client-customer-management-manager-organizer-mobile-iphone-ios-android-app-tool-for-gym-trainers-training-club',
                title: '💪 ADP Gym — iOS/Android Mobile App',
                subtitle: 'Private Gym App for Trainers & Clients',
                image: require('../../assets/images/portfolio/app-icons/ADP_Gym_Logo.png'),
                video: 'https://www.youtube.com/embed/SnC6yuEgBSk',
                highlightColor: '#941100',
                introduction: {
                    summary: `Built an app for private gym trainers to manage their clients, and for the clients to make their payments & manage their workouts.`,
                    techStack: `React Native, Typescript, Expo, React, HTML, Python API's, Stripe, and Google Firebase`,
                    teamAndTime: `5 months`,
                    personalContributions: [
                        `One of my students asked her private gym if they wanted an app, and they did, so I built one for them.`,
                        `I wrote the iOS / Android React Native mobile app from scratch.`,
                        `I built the NoSQL database using Google Firebase.`,
                        `The app connects to a custom-coded Python API that I also wrote from scratch.`,
                        `The Python API also serves as an HTML website for the client's to securely make payments to the gym. This was setup through Stripe.`,
                    ],
                },
                links: [
                    {
                        title: 'ADP Gym Website',
                        url: 'https://alldayphysique.com/',
                    },
                ],
                body: [
                    {
                        text: `CORRECTION TO VIDEO: I have added a feature for users to pay for their gym sessions within the app!`
                    },
                    {
                        text: `If you have any questions or concerns about the ADP Gym app, then please either schedule a zoom call with me or send me a message below. I'd be happy to show you how it works under-the-hood, but my codebase is not public.`
                    }
                ]
            },
            // DATA ROBOT
            {
                route: 'data-robot-10x-data-science-academy-certification-guide',
                title: `🤖 DataRobot's 10x Data Science Academy Certification`,
                image: require('../../assets/images/portfolio/posts/datarobot/Data-Robot-10x-Data-Science-Academy-Certification-Guide-Notes-Study-Curriculum-Ryan-Zernach-blog.png'),
                subtitle: `After learning how to train machine learning models at Lambda School, I was seriously impressed with DataRobot's AutoML platform.`,
                video: 'https://www.youtube.com/embed/0IBtIVNXoGQ',
                highlightColor: colors.blue,
                introduction: {
                    summary: `As a member of the inaugural cohort of DataRobot's 10x Data Science Academy, I learned how to more effectively solve data science problems by using DataRobot's automated machine learning (AutoML) platform.`,
                    techStack: `DataRobot Platform`,
                },
                body: [
                    {
                        text: `MINI PROJECTS`
                    },
                    {
                        text: `PREDICTING SALARIES OF STACKOVERFLOW USERS`,
                        color: colors.blue
                    },
                    {
                        image: require('../../assets/images/portfolio/posts/datarobot/Importance-Validation-predictive-algorithm-machine-learning-model-DataRobot-Ryan-Zernach-708x532x0x0x708x532x1596905994.png'),
                        caption: `FEATURE IMPORTANCES SHOW “DevType” AS THE MOST INFLUENTIAL FEATURE IN PREDICTING THE SALARY OF A DEVELOPER`
                    },
                    {
                        image: require('../../assets/images/portfolio/posts/datarobot/Blueprint-predictive-algorithm-machine-learning-model-DataRobot-Ryan-Zernach-708x532x0x0x708x532x1596905996.png'),
                        caption: `BLUEPRINT OF THE MODEL SHOWS THAT THERE ARE MANY TEXT FEATURES THAT ARE BEING NUMERICALLY ENCODED FOR DATA PREP`
                    },
                    {
                        image: require('../../assets/images/portfolio/posts/datarobot/predictions_distribution-validation-predictive-algorithm-machine-learning-model-DataRobot-Ryan-Zernach-1536x568x0x0x1536x568x1596905979.png'),
                        caption: `THE MODEL TENDS TO OVER-PREDICT FOR HIGHER SALARIES & UNDER-PREDICT FOR LOWER SALARIES`
                    },
                    {
                        image: require('../../assets/images/portfolio/posts/datarobot/lift-CV-predictive-algorithm-machine-learning-model-DataRobot-Ryan-Zernach-708x532x0x0x708x532x1596905985.png'),
                        caption: `THE BEAUTY OF VISUALIZING THE PREDICTED VALUES VERSUS ACTUAL VALUES — ZOOM IN!`
                    },
                    {
                        image: require('../../assets/images/portfolio/posts/datarobot/fam-predictive-algorithm-machine-learning-model-DataRobot-Ryan-Zernach-708x532x0x0x708x532x1596905983.png'),
                        caption: `THE FEATURE IMPORTANCE MAP (IF YOU ZOOM IN) SHOWS THAT THERE IS CORRELATION “CLUSTERS” AMONG THE FEATURES THAT ARE COLORED (NOT WHITE) — MOST PRIMARILY ORANGES & GREENS`
                    },
                    {
                        image: require('../../assets/images/portfolio/posts/datarobot/DataRobot-Deployed-What-If-dashboard-to-open-the-black-box-and-explain-the-output-of-the-model-Ryan-Zernach-1476x802x0x0x1476x802x1596934865.png'),
                        caption: `BUILT-IN DASHBOARD FOR MAKING PREDICTIONS USING SALARY-PREDICTION MODEL`
                    },
                    {
                        spacer: 100,
                    },
                    {
                        text: `GENERATING FUEL EFFICIENCY PREDICTIONS FOR FUTURE VEHICLES`,
                        color: colors.blue
                    },
                    {
                        text: `This prediction algorithm can be used to predict a vehicle's fuel efficiency, miles-per-gallon (MPG), based on the vehicle's mechanical specifications. However, a greater number of vehicle manufacturers are continuing to make the switch to producing electric vehicles.`
                    },
                    {
                        text: `If I were to generate a model to predict an electric vehicle's miles-per-kWh (kilowatt hour) electric efficiency, I would not use cylinders, transmissions, exhaust valves, nor ethanol — which are most of the features within the MPG dataset. Instead, I would primarily be focused on the laws of physics: weight & aerodynamics. How much does the intended electric vehicle weigh, and how much friction is caused with air particles as the vehicle slices through the atmosphere? These are all questions that could be answered by vehicle design software before it's even prototyped/produced.`
                    },
                    {
                        image: require('../../assets/images/portfolio/posts/datarobot/DataRobot-Deployed-What-If-dashboard-to-open-the-black-box-and-explain-the-output-of-the-model-Ryan-Zernach2-1416x776x0x8x1416x760x1596994537.png'),
                        caption: `BUILT-IN DASHBOARD FOR MAKING PREDICTIONS USING MPG-PREDICTIONS MODEL`
                    },
                    {
                        spacer: 100,
                    },
                    {
                        text: `CREATING PREDICTIONS FOR WHICH COUNTY WILL NEXT BE INFECTED WITH COVID-19`,
                        color: colors.blue
                    },
                    {
                        image: require('../../assets/images/portfolio/posts/datarobot/caa-roc_dist-CV-DataRobot-Predicting-COVID-machine-learning-Ryan-Zernach-708x532x0x0x708x532x1597362155.png'),
                        caption: ``
                    },
                    {
                        image: require('../../assets/images/portfolio/posts/datarobot/coefficients-stage_1-DataRobot-Predicting-COVID-machine-learning-Ryan-Zernach-708x532x0x0x708x532x1597362157.png'),
                        caption: ``
                    },
                    {
                        image: require('../../assets/images/portfolio/posts/datarobot/Blueprint-2-predictive-algorithm-machine-learning-model-DataRobot-Ryan-Zernach-708x532x0x0x708x532x1597362169.png'),
                        caption: ``
                    },
                    {
                        image: require('../../assets/images/portfolio/posts/datarobot/caa-lift-CV-DataRobot-Predicting-COVID-machine-learning-Ryan-Zernach-708x532x0x0x708x532x1597362168.png'),
                        caption: ``
                    },
                    {
                        text: `MULTICOLLINEARITY AMONGST FEATURES DOESN'T HAVE A NEGATIVE EFFECT ON MODEL ACCURACY, BUT IT REDUCES  INTERPRETABILITY OF THE EFFECTS OF CORRELATED FEATURES ON THE TARGET ACCURACY`
                    },
                    {
                        text: `FEATURE CORRELATION MAP SHOWS (12) DIFFERENT CORRELATION “CLUSTERS” AMONG FEATURES`
                    },
                    {
                        image: require('../../assets/images/portfolio/posts/datarobot/caa-fam-DataRobot-Predicting-COVID-machine-learning-Ryan-Zernach-1476x1108x0x0x1476x1108x1597362165.png'),
                        caption: `NOTE: bolded have_confirmed_18 s the target feature to be predicted`
                    },
                    {
                        image: require('../../assets/images/portfolio/posts/datarobot/DATAROBOT-Ryan-Zenrach.-feature_effects-R_birth_2018-1416x654x0x0x1416x654x1597452194.png'),
                        caption: ``
                    },
                    {
                        image: require('../../assets/images/portfolio/posts/datarobot/datarobot-ryan-zernach-feature_effects-POP_ESTIMATE_2018-1416x654x0x0x1416x654x1597452186.png'),
                        caption: ``
                    },
                    {
                        image: require('../../assets/images/portfolio/posts/datarobot/caa-gain_chart-CV-DataRobot-Predicting-COVID-machine-learning-Ryan-Zernach.png-708x532x0x2x708x526x1597362171.png'),
                        caption: ``
                    },
                    {
                        image: require('../../assets/images/portfolio/posts/datarobot/caa-cumulative_lift-CV-DataRobot-Predicting-COVID-machine-learning-Ryan-Zernach-708x532x0x0x708x532x1597362154.png'),
                        caption: ``
                    },
                ]
            },
            // AWS CLOUD PRACTITIONER
            {
                route: 'aws-certified-cloud-practitioner-study-guide',
                title: '☁️ AWS Certified Cloud Practitioner Study Guide',
                highlightColor: colors.pink,
                image: require('../../assets/images/portfolio/posts/aws/AWS_Certified_Cloud_Practitioner_Ryan_Zernach_Cloud_Foundations-Engineer-Computing-Databases-Remote.png'),
                subtitle: `The official certification exam was 65 questions long. Prior to the exam, I studied by completing AWS Academy's 20-hour Cloud Foundations curriculum, which was was composed of videos, quizzes, and hands-on training in an AWS sandbox environment. Additionally, I completed an Udemy course to study for the exam. Also, I do have real-world experience with several of AWS's microservices. Please see Portfolio Projects.`,
                body: [
                    {
                        image: require('../../assets/images/portfolio/posts/aws/Completion_Ryan_Zernach_AWS_Cloud_Practitioner_Badge_Exam_Test_Study_Guide.png'),
                        url: `https://www.youracclaim.com/badges/85bea90d-4421-46fa-b92a-aa12b5bf94ec`,
                        caption: `Click image to verify my certification.`
                    },
                    {
                        imageGrid: {
                            title: `Screenshots of Cloud Foundations quiz questions:`,
                            images: [
                                require('../../assets/images/portfolio/posts/aws/1-Cloud-Concepts-502x256x46x0x408x256x1594332116.png'),
                                require('../../assets/images/portfolio/posts/aws/1-Cloud-Concepts-502x256x46x0x408x256x1594332116.png'),
                                require('../../assets/images/portfolio/posts/aws/1-Cloud-Concepts-502x256x46x0x408x256x1594332116.png'),
                                require('../../assets/images/portfolio/posts/aws/1-Cloud-Concepts-502x256x46x0x408x256x1594332116.png'),
                                require('../../assets/images/portfolio/posts/aws/1-Cloud-Concepts-502x256x46x0x408x256x1594332116.png'),
                                require('../../assets/images/portfolio/posts/aws/1-Cloud-Concepts-502x256x46x0x408x256x1594332116.png'),
                                require('../../assets/images/portfolio/posts/aws/1-Cloud-Concepts-502x256x46x0x408x256x1594332116.png'),
                                require('../../assets/images/portfolio/posts/aws/1-Cloud-Concepts-502x256x46x0x408x256x1594332116.png'),
                                require('../../assets/images/portfolio/posts/aws/1-Cloud-Concepts-502x256x46x0x408x256x1594332116.png'),
                                require('../../assets/images/portfolio/posts/aws/1-Cloud-Concepts-502x256x46x0x408x256x1594332116.png'),
                            ]
                        }
                    }
                ]
            },
            // TENSORFLOW
            {
                route: 'tensorflow-developer-certification-study-guide',
                title: '💻 TensorFlow Developer Certification Study Guide',
                subtitle: `Although I worked with TensorFlow in Lambda School, I wanted to earn an official TensorFlow Developer certification, so I studied for it on my own time by using Coursera.`,
                image: require('../../assets/images/portfolio/posts/tensorflow/Tensorflow-Study-Guide-Ryan-Zernach-Coursera-Credit-Certification.png'),
                highlightColor: '#FF6F00',
                links: [
                    {
                        title: `Thank you, Coursera!`,
                        icon: <GiTiedScroll />,
                        url: `https://www.coursera.org/specializations/tensorflow-in-practice`,
                    }
                ],
                body: [
                    {
                        image: require('../../assets/images/portfolio/posts/tensorflow/TensorFlow-in-Practice-Specialization-Ryan-Zernach-Coursera-4-Part-Series-Course-Certificate-1536x1190x0x0x1536x1190x1595191747.png'),
                    },
                    {
                        text: `Course #1 — Introduction to TensorFlow for Artificial Intelligence, Machine Learning, and Deep Learning`,
                        url: `https://www.coursera.org/learn/introduction-tensorflow`
                    },
                    {
                        accordions: [
                            {
                                title: `Course #1 — Certificate of Completion`,
                                content: [
                                    {
                                        image: require('../../assets/images/portfolio/posts/tensorflow/TensorFlow-in-Practice-Specialization-Ryan-Zernach-Coursera-4-Part-Series-Course-Certificate-1536x1190x0x0x1536x1190x1595191747.png'),
                                        caption: 'Completed in July 2020'
                                    }
                                ]
                            },
                            {
                                title: `Week 1 — A New Programming Paradigm`,
                                content: [
                                    {
                                        button: {
                                            title: `1-1-A`,
                                            url: `https://colab.research.google.com/drive/1wBOlwAHjpoCAN_B4lr5-jZn7yYfDp8H6?usp=sharing`,
                                            icon: <BsCode />
                                        }
                                    },
                                    {
                                        button: {
                                            title: `1-1-Exercise`,
                                            url: `https://colab.research.google.com/drive/1Mckpvkb2tBhcV8aYmngjlLPnIMS-T8BO?usp=sharing`,
                                            icon: <BsCode />
                                        }
                                    },
                                    {
                                        header: `QUIZ`,
                                    },
                                    {
                                        text: `Question 1 — The diagram for traditional programming had Rules and Data In, but what came out?`,
                                        subtext: `Answers`
                                    },
                                    {
                                        text: `Question 2 — The diagram for Machine Learning had Answers and Data In, but what came out?`,
                                        subtext: `Rules`
                                    },
                                    {
                                        text: `Question 3 — When I tell a computer what the data represents (i.e. this data is for walking, this data is for running), what is that process called?`,
                                        subtext: `Labelling`
                                    },
                                    {
                                        text: `Question 4 — What is a Dense?`,
                                        subtext: `A layer of connected neurons`
                                    },
                                    {
                                        text: `Question 5 — What does a Loss function do?`,
                                        subtext: `Measures how good the current 'guess' is`
                                    },
                                    {
                                        text: `Question 6 — What does the optimizer do?`,
                                        subtext: `Generates a new and improved guess`
                                    },
                                    {
                                        text: `Question 7 — What is Convergence?`,
                                        subtext: `The process of getting very close to the correct answer`
                                    },
                                    {
                                        text: `Question 8 — What does model.fit do?`,
                                        subtext: `It trains the neural network to fit one set of values to another`
                                    },
                                ]
                            },
                            {
                                title: `Week 2 — Introduction to Computer Vision`,
                                content: [
                                    {
                                        button: {
                                            title: `1-2-A`,
                                            url: `https://colab.research.google.com/drive/16hDHbrvq6OEu3IN0XiVSShls5MowBXbZ?usp=sharing`,
                                            icon: <BsCode />
                                        }
                                    },
                                    {
                                        button: {
                                            title: `1-2-Exercise`,
                                            url: `https://colab.research.google.com/drive/1oVrSAhjzG_9fT0iYGPEaCOe9YZUBPfXR?usp=sharing`,
                                            icon: <BsCode />
                                        }
                                    },
                                    {
                                        header: `QUIZ`,
                                    },
                                    {
                                        text: `Question 1 — What's the name of the dataset of Fashion images used in this week's code?`,
                                        subtext: `Fashion MNIST`
                                    },
                                    {
                                        text: `Question 2 — What do the above mentioned Images look like?`,
                                        subtext: `28x28 Greyscale`
                                    },
                                    {
                                        text: `Question 3 — How many images are in the Fashion MNIST dataset?`,
                                        subtext: `70,000`
                                    },
                                    {
                                        text: `Question 4 — Why are there 10 output neurons?`,
                                        subtext: `There are 10 different labels`
                                    },
                                    {
                                        text: `Question 5 — What does Relu do?`,
                                        subtext: `It only returns x if x is greater than zero`
                                    },
                                    {
                                        text: `Question 6 — Why do you split data into training and test sets?`,
                                        subtext: `To test a network with previously unseen data`
                                    },
                                    {
                                        text: `Question 7 — What method gets called when an epoch finishes?`,
                                        subtext: `on_epoch_end`
                                    },
                                    {
                                        text: `Question 8 — What parameter to you set in your fit function to tell it to use callbacks?`,
                                        subtext: `callbacks=`
                                    },
                                ]
                            },
                            {
                                title: `Week 3 — Enhancing Vision with CNN's`,
                                content: [
                                    {
                                        button: {
                                            title: `1-3-A`,
                                            url: `https://colab.research.google.com/drive/1gSk9yw8tgY0L3aXFKTUSy9CG7potOyyA?usp=sharing`,
                                            icon: <BsCode />
                                        }
                                    },
                                    {
                                        button: {
                                            title: `Fun Convolutional Filters`,
                                            url: `https://lodev.org/cgtutor/filtering.html`,
                                            icon: <BsCode />
                                        }
                                    },
                                    {
                                        button: {
                                            title: `1-3-B`,
                                            url: `https://colab.research.google.com/drive/1CetA3Xyosa7tp7rVnTdZ4J485_CQkC8T?usp=sharing`,
                                            icon: <BsCode />
                                        }
                                    },
                                    {
                                        button: {
                                            title: `1-3-Exercise`,
                                            url: `https://colab.research.google.com/drive/1Q75C4F1rr1OpoU-N8rxlCz2xalAXzrTD?usp=sharing`,
                                            icon: <BsCode />
                                        }
                                    },
                                    {
                                        button: {
                                            title: `Youtube Series on CNN's`,
                                            url: `https://bit.ly/2UGa7uH`,
                                            icon: <BsCode />
                                        }
                                    },
                                    {
                                        header: `QUIZ`,
                                    },
                                    {
                                        text: `Question 1 — What is a Convolution?`,
                                        subtext: `A technique to isolate features in images`
                                    },
                                    {
                                        text: `Question 2 — What is a Pooling?`,
                                        subtext: `A technique to reduce the information in an image while maintaining features`
                                    },
                                    {
                                        text: `Question 3 — How do Convolutions improve image recognition?`,
                                        subtext: `They isolate features in images`
                                    },
                                    {
                                        text: `Question 4 — After passing a 3x3 filter over a 28x28 image, how big will the output be?`,
                                        subtext: `26x26`
                                    },
                                    {
                                        text: `Question 5 — After max pooling a 26x26 image with a 2x2 filter, how big will the output be?`,
                                        subtext: `13x13`
                                    },
                                    {
                                        text: `Question 6 — Applying Convolutions on top of our Deep neural network will make training:`,
                                        subtext: `It depends on many factors. It might make your training faster or slower, and a poorly designed Convolutional layer may even be less efficient than a plain DNN!`
                                    },
                                ]
                            },
                            {
                                title: `Week 4 — Using Real-World Images`,
                                content: [
                                    {
                                        button: {
                                            title: `1-4-A`,
                                            url: `https://colab.research.google.com/drive/1izqOhC8-X54K_e69HNZrO5DZukAQMhNi?usp=sharing`,
                                            icon: <BsCode />
                                        }
                                    },
                                    {
                                        button: {
                                            title: `1-4-B`,
                                            url: `https://colab.research.google.com/drive/1BT5stsKcFrL8qguIvOwZo_YzVEaB488J?usp=sharing`,
                                            icon: <BsCode />
                                        }
                                    },
                                    {
                                        button: {
                                            title: `1-4-Exercise`,
                                            url: `https://colab.research.google.com/drive/1kOLgmlugjfW3kwfQBTiHyWl2ciSM4kAG?usp=sharing`,
                                            icon: <BsCode />
                                        }
                                    },
                                    {
                                        header: `QUIZ`,
                                    },
                                    {
                                        text: `Question 1 — Using Image Generator, how do you label images?`,
                                        subtext: `It's based on the directory the image is contained in`
                                    },
                                    {
                                        text: `Question 2 — What method on the Image Generator is used to normalize the image?`,
                                        subtext: `rescale`
                                    },
                                    {
                                        text: `Question 3 — How did we specify the training size for the images?`,
                                        subtext: `The target_size parameter on the training generator`
                                    },
                                    {
                                        text: `Question 4 — When we specify the input_shape to be (300, 300, 3), what does that mean?`,
                                        subtext: `Every Image will be 300x300 pixels, with 3 bytes to define color`
                                    },
                                    {
                                        text: `Question 5 — If your training data is close to 1.000 accuracy, but your validation data isn't, what's the risk here?`,
                                        subtext: `You're overfitting on your training data`
                                    },
                                    {
                                        text: `Question 6 — Convolutional Neural Networks are better for classifying images like horses and humans because:`,
                                        subtext: `All of the above (The distinguishable features may be in different parts of the frame, There's a wide variety of horses, There's a wide variety of humans)`
                                    },
                                    {
                                        text: `Question 7 — After reducing the size of the images, the training results were different. Why?`,
                                        subtext: `We removed some convolutions to handle the smaller images`
                                    },
                                ]
                            }
                        ]
                    },
                    {
                        text: `Course #2 — Convolutional Neural Networks in TensorFlow`,
                        url: `https://www.coursera.org/learn/convolutional-neural-networks-tensorflow`
                    },
                    {
                        accordions: [
                            {
                                title: `Course #2 — Certificate of Completion`,
                                content: [
                                    {
                                        image: require('../../assets/images/portfolio/posts/tensorflow/Convolutional-Neural-Networks-in-TensorFlow-Ryan-Zernach-Coursera-Certificate-2340x1810x0x0x2340x1810x1594747657.png'),
                                        caption: 'Completed in July 2020'
                                    }
                                ]
                            },
                            {
                                title: `Week 1 — Exploring a Larger Dataset`,
                                content: [
                                    {
                                        button: {
                                            title: `2-1-A`,
                                            url: `https://colab.research.google.com/drive/1EpTM7sE8Z3yL0ZYKDkpOcYNSxczCYIrp?usp=sharing`,
                                            icon: <BsCode />
                                        }
                                    },
                                    {
                                        button: {
                                            title: `2-1-Exercise (Cats vs. Dogs)`,
                                            url: `https://colab.research.google.com/drive/1QBmqmUKYwQcDSHCo2kFI_HfJmivj8yed?usp=sharing`,
                                            icon: <BsCode />
                                        }
                                    },
                                    {
                                        header: `QUIZ`,
                                    },
                                    {
                                        text: `Question 1 — What does flow_from_directory give you on the ImageGenerator?`,
                                        subtext: `All of the Above (ability to easily load images for training, ability to pick the size of training images, ability to automatically label images based on their directory name)`
                                    },
                                    {
                                        text: `Question 2 — If my Image is sized 150×150, and I pass a 3×3 Convolution over it, what size is the resulting image?`,
                                        subtext: `148x148`
                                    },
                                    {
                                        text: `Question 3 — If my data is sized 150×150, and I use Pooling of size 2×2, what size will the resulting image be?`,
                                        subtext: `75x75`
                                    },
                                    {
                                        text: `Question 4 — If I want to view the history of my training, how can I access it?`,
                                        subtext: `Create a variable 'history' and assign it to the return of model.fit or model.fit_generator`
                                    },
                                    {
                                        text: `Question 5 — What's the name of the API that allows you to inspect the impact of convolutions on the images?`,
                                        subtext: `The model.layers API`
                                    },
                                    {
                                        text: `Question 6 — When exploring the graphs, the loss levelled out at about .75 after 2 epochs, but the accuracy climbed close to 1.0 after 15 epochs. What's the significance of this?`,
                                        subtext: `There was no point training after 2 epochs, as we overfit to the training data`
                                    },
                                    {
                                        text: `Question 7 — Why is the validation accuracy a better indicator of model performance than training accuracy?`,
                                        subtext: `The validation accuracy is based on images that the model hasn't been trained with, and thus a better indicator of how the model will perform with new images`
                                    },
                                    {
                                        text: `Question 8 — Why is overfitting more likely to occur on smaller datasets?`,
                                        subtext: `Because there's less likelihood of all possible features being encountered in the training process.`
                                    },
                                ]
                            },
                            {
                                title: `Week 2 — Augmentation: A Technique to Avoid Overfitting`,
                                content: [
                                    {
                                        button: {
                                            title: `2-2-A`,
                                            url: `https://colab.research.google.com/drive/1RzRHCzelw5mZetrm4rfc4AfF_hnBHZ5l?usp=sharing`,
                                            icon: <BsCode />
                                        }
                                    },
                                    {
                                        button: {
                                            title: `2-2-B`,
                                            url: `https://colab.research.google.com/drive/1hrPksbV_LdotT46u_U9OHfhKKzuxrsoB?usp=sharing`,
                                            icon: <BsCode />
                                        }
                                    },
                                    {
                                        button: {
                                            title: `2-2-C`,
                                            url: `https://colab.research.google.com/drive/1trHJIQf9N2Nbw1rdwVtpmOxoOEANNxv3?usp=sharing`,
                                            icon: <BsCode />
                                        }
                                    },
                                    {
                                        button: {
                                            title: `2-2-Exercise (Cats vs. Dogs using Augmentation)`,
                                            url: `https://colab.research.google.com/drive/1BqNiJRRE96bk_yJXkpNrxRKtOjuLu6Td?usp=sharing`,
                                            icon: <BsCode />
                                        }
                                    },
                                    {
                                        header: `QUIZ`,
                                    },
                                    {
                                        text: `Question 1 — How do you use Image Augmentation in TensorFlow?`,
                                        subtext: `Using parameters to the ImageDataGenerator`
                                    },
                                    {
                                        text: `Question 2— If my training data only has people facing left, but I want to classify people facing right, how would I avoid overfitting?`,
                                        subtext: `Use the 'horizontal_flip' parameter`
                                    },
                                    {
                                        text: `Question 3 — When training with augmentation, you noticed that the training is a little slower. Why?`,
                                        subtext: `Because the image processing takes cycles `
                                    },
                                    {
                                        text: `Question 4 — What does the fill_mode parameter do? `,
                                        subtext: `It attempts to recreate lost information after a transformation like a shear `
                                    },
                                    {
                                        text: `Question 5 — When using Image Augmentation with the ImageDataGenerator, what happens to your raw image data on-disk?`,
                                        subtext: `Nothing, all augmentation is done in-memory `
                                    },
                                    {
                                        text: `Question 6 — How does Image Augmentation help solve overfitting?`,
                                        subtext: `It manipulates the training set to generate more scenarios for features in the images `
                                    },
                                    {
                                        text: `Question 7 — When using Image Augmentation my training gets…`,
                                        subtext: `Slower`
                                    },
                                    {
                                        text: `Question 8 — True or False? Using Image Augmentation effectively simulates having a larger data set for training.`,
                                        subtext: `True`
                                    },
                                ]
                            },
                            {
                                title: `Week 3 — Transfer Learning`,
                                content: [
                                    {
                                        button: {
                                            title: `2-3-A`,
                                            url: `https://colab.research.google.com/drive/1PJLEajj5E6bJblSRvqPAuPPVbWV9sEkJ?usp=sharing`,
                                            icon: <BsCode />
                                        }
                                    },
                                    {
                                        button: {
                                            title: `2-3-Exercise`,
                                            url: `https://colab.research.google.com/drive/1VTXNTKnLKWeMhVqz8FhFoUarlT_4YdqM?usp=sharing`,
                                            icon: <BsCode />
                                        }
                                    },
                                    {
                                        header: `QUIZ`,
                                    },
                                    {
                                        text: `Question 1 — If I put a dropout parameter of 0.2, how many nodes will I lose?`,
                                        subtext: `20% of them`
                                    },
                                    {
                                        text: `Question 2 — Why is transfer learning useful?`,
                                        subtext: `Because I can use the features that were learned from large datasets that I may not have access to`
                                    },
                                    {
                                        text: `Question 3 — How did you lock or freeze a layer from retraining?`,
                                        subtext: `layer.trainable = false`
                                    },
                                    {
                                        text: `Question 4 — How do you change the number of classes the model can classify when using transfer learning? (i.e. the original model handled 1000 classes, but yours handles just 2)`,
                                        subtext: `When you add your DNN at the bottom of the network, you specify your output layer with the number of classes you want`
                                    },
                                    {
                                        text: `Question 5 — Can you use Image Augmentation with Transfer Learning Models?`,
                                        subtext: `Yes, because you are adding new layers at the bottom of the network, and you can use image augmentation when training these`
                                    },
                                    {
                                        text: `Question 6 — Why do dropouts help avoid overfitting?`,
                                        subtext: `Because neighbor neurons can have similar weights, and thus can skew the final training`
                                    },
                                    {
                                        text: `Question 7 — What would the symptom of a Dropout rate being set too high?`,
                                        subtext: `The network would lose specialization to the effect that it would be inefficient or ineffective at learning, driving accuracy down`
                                    },
                                    {
                                        text: `Question 8 — Which is the correct line of code for adding Dropout of 20% of neurons using TensorFlow?`,
                                        subtext: `tf.keras.layers.Dropout(0.2)`
                                    },
                                ]
                            },
                            {
                                title: `Week 4 — Multi-Class Classification`,
                                content: [
                                    {
                                        button: {
                                            title: `2-4-A`,
                                            url: `https://colab.research.google.com/drive/1sV1AvzeP7171jiSjTYYkVm8AYfjXLVn3?usp=sharing`,
                                            icon: <BsCode />
                                        }
                                    },
                                    {
                                        button: {
                                            title: `2-4-Exercise`,
                                            url: `https://colab.research.google.com/drive/1h5MjAPbT05vPgvS9bEko5Klue6usyLl9?usp=sharing`,
                                            icon: <BsCode />
                                        }
                                    },
                                    {
                                        header: `QUIZ`,
                                    },
                                    {
                                        text: `Question 1 — The diagram for traditional programming had Rules and Data In, but what came out?`,
                                        subtext: `Answers`
                                    },
                                    {
                                        text: `Question 2 — Why does the DNN for Fashion MNIST have 10 output neurons?`,
                                        subtext: `The dataset has 10 classes`
                                    },
                                    {
                                        text: `Question 3 — What is a Convolution?`,
                                        subtext: `A technique to extract features from an image`
                                    },
                                    {
                                        text: `Question 4 — Applying Convolutions on top of a DNN will have what impact on training?`,
                                        subtext: `It depends on many factors. It might make your training faster or slower, and a poorly designed Convolutional layer may even be less efficient than a plain DNN!`
                                    },
                                    {
                                        text: `Question 5 — What method on an ImageGenerator is used to normalize the image?`,
                                        subtext: `rescale`
                                    },
                                    {
                                        text: `Question 6 — When using Image Augmentation with the ImageDataGenerator, what happens to your raw image data on-disk.`,
                                        subtext: `Nothing`
                                    },
                                    {
                                        text: `Question 7 — Can you use Image augmentation with Transfer Learning?`,
                                        subtext: `Yes. It's pre-trained layers that are frozen. So you can augment your images as you train the bottom layers of the DNN with them`
                                    },
                                    {
                                        text: `Question 8 — When training for multiple classes what is the Class Mode for Image Augmentation?`,
                                        subtext: `class_mode='categorical'`
                                    },
                                ]
                            }
                        ]
                    },
                    {
                        text: `Course #3 — Natural Language Processing in TensorFlow`,
                        url: `https://www.coursera.org/learn/natural-language-processing-tensorflow`
                    },
                    {
                        accordions: [
                            {
                                title: `Course #3 — Certificate of Completion`,
                                content: [
                                    {
                                        image: require('../../assets/images/portfolio/posts/tensorflow/Natural-Language-Processing-in-TensorFlow-—-Coursera-Certificate-—-Ryan-Zernach-2340x1812x0x0x2340x1812x1594918977.png'),
                                        caption: 'Completed in July 2020'
                                    }
                                ]
                            },
                            {
                                title: `Week 1 — Sentiment in Text`,
                                content: [
                                    {
                                        button: {
                                            title: `3-1-A`,
                                            url: `https://colab.research.google.com/drive/1azWk1UuyNtMoA4hp8E_9OmtXdwPMYr0e?usp=sharing`,
                                            icon: <BsCode />
                                        }
                                    },
                                    {
                                        button: {
                                            title: `3-1-B`,
                                            url: `https://colab.research.google.com/drive/18WZgJxAjaO9PV5qWM-A87K3_vmLp9vVG?usp=sharing`,
                                            icon: <BsCode />
                                        }
                                    },
                                    {
                                        button: {
                                            title: `3-1-C`,
                                            url: `https://colab.research.google.com/drive/18WZgJxAjaO9PV5qWM-A87K3_vmLp9vVG?usp=sharing`,
                                            icon: <BsCode />
                                        }
                                    },
                                    {
                                        button: {
                                            title: `3-1-Exercise`,
                                            url: `https://colab.research.google.com/drive/11Pl6eE--Q8oTBPMNyfcDXcvpbIDCbECq?usp=sharing`,
                                            icon: <BsCode />
                                        }
                                    },
                                    {
                                        header: `QUIZ`,
                                    },
                                    {
                                        text: `Question 1 — What is the name of the object used to tokenize sentences?`,
                                        subtext: `Tokenizer`
                                    },
                                    {
                                        text: `Question 2 — What is the name of the method used to tokenize a list of sentences?`,
                                        subtext: `fit_on_texts(sentences)`
                                    },
                                    {
                                        text: `Question 3 — Once you have the corpus tokenized, what's the method used to encode a list of sentences to use those tokens?`,
                                        subtext: `texts_to_sequences(sentences)`
                                    },
                                    {
                                        text: `Question 4 — When initializing the tokenizer, how do you specify a token to use for unknown words?`,
                                        subtext: `oov_token=<Token>`
                                    },
                                    {
                                        text: `Question 5 — If you don't use a token for out of vocabulary words, what happens at encoding?`,
                                        subtext: `The word isn't encoded, and is skipped in the sequence`
                                    },
                                    {
                                        text: `Question 6 — If you have a number of sequences of different lengths, how do you ensure that they are understood when fed into a neural network?`,
                                        subtext: `Use the pad_sequences object from the tensorflow.keras.preprocessing.sequence namespace`
                                    },
                                    {
                                        text: `Question 7 — If you have a number of sequences of different length, and call pad_sequences on them, what's the default result?`,
                                        subtext: `They'll get padded to the length of the longest sequence by adding zeros to the beginning of shorter ones`
                                    },
                                    {
                                        text: `Question 8 — When padding sequences, if you want the padding to be at the end of the sequence, how do you do it?`,
                                        subtext: `Pass padding='post' to pad_sequences when initializing it`
                                    },
                                ]
                            },
                            {
                                title: `Week 2 — Word Embeddings`,
                                content: [
                                    {
                                        button: {
                                            title: `3-2-A`,
                                            url: `https://colab.research.google.com/drive/1nQbmEUBjiX4-rIC6ijtH_xM2_zwXF8DW?usp=sharing`,
                                            icon: <BsCode />
                                        }
                                    },
                                    {
                                        button: {
                                            title: `3-2-B`,
                                            url: `https://colab.research.google.com/drive/16S1iZXGa9S436s7-NbuSuU_I6B1q3Bud?usp=sharing`,
                                            icon: <BsCode />
                                        }
                                    },
                                    {
                                        button: {
                                            title: `3-2-C`,
                                            url: `https://colab.research.google.com/drive/1auT6p5o6pe9pxptdeBP-EtwPsU58GeZn?usp=sharing`,
                                            icon: <BsCode />
                                        }
                                    },
                                    {
                                        button: {
                                            title: `Tensorflow Embedding Projector`,
                                            url: `http://projector.tensorflow.org/`,
                                            icon: <BsCode />
                                        }
                                    },
                                    {
                                        button: {
                                            title: `3-2-Exercise`,
                                            url: `https://colab.research.google.com/drive/1lvcxhAVYMOZ3nzizwb6r0ahUrgMZrnM8?usp=sharing`,
                                            icon: <BsCode />
                                        }
                                    },
                                    {
                                        header: `QUIZ`,
                                    },
                                    {
                                        text: `Question 1 — What is the name of the TensorFlow library containing common data that you can use to train and test neural networks?`,
                                        subtext: `TensorFlow Datasets`
                                    },
                                    {
                                        text: `Question 2 — How many reviews are there in the IMDB dataset and how are they split?`,
                                        subtext: `50,000 records, 50/50 train/test split`
                                    },
                                    {
                                        text: `Question 3 — How are the labels for the IMDB dataset encoded?`,
                                        subtext: `Reviews encoded as a number 0-1`
                                    },
                                    {
                                        text: `Question 4 — What is the purpose of the embedding dimension?`,
                                        subtext: `It is the number of dimensions for the vector representing the word encoding`
                                    },
                                    {
                                        text: `Question 5 — When tokenizing a corpus, what does the num_words=n parameter do?`,
                                        subtext: `It specifies the maximum number of words to be tokenized, and picks the most common 'n' words`
                                    },
                                    {
                                        text: `Question 6 — To use word embeddings in TensorFlow, in a sequential layer, what is the name of the class?`,
                                        subtext: `tf.keras.layers.Embedding`
                                    },
                                    {
                                        text: `Question 7 — IMDB Reviews are either positive or negative. What type of loss function should be used in this scenario?`,
                                        subtext: `Binary crossentropy`
                                    },
                                    {
                                        text: `Question 8 — When using IMDB Sub Words dataset, our results in classification were poor. Why?`,
                                        subtext: `Sequence becomes much more important when dealing with subwords, but we're ignoring word positions`
                                    },
                                ]
                            },
                            {
                                title: `Week 3 — Sequence Models`,
                                content: [
                                    {
                                        button: {
                                            title: `3-3-A`,
                                            url: `https://colab.research.google.com/drive/1AAZ4VZkIpRSQDPosx609aFLrr-WaWsYO?usp=sharing`,
                                            icon: <BsCode />
                                        }
                                    },
                                    {
                                        button: {
                                            title: `3-3-B`,
                                            url: `https://colab.research.google.com/drive/163jDAv96v8igPY3gtGiis6e37I1O6-6t?usp=sharing`,
                                            icon: <BsCode />
                                        }
                                    },
                                    {
                                        button: {
                                            title: `3-3-C`,
                                            url: `https://colab.research.google.com/drive/1f45_yId8E42vU32AO3tJpJdRW04u1cxK?usp=sharing`,
                                            icon: <BsCode />
                                        }
                                    },
                                    {
                                        button: {
                                            title: `3-3-D`,
                                            url: `https://colab.research.google.com/drive/1Yi7dglgnunG5FtmfrJMgnfRP-MNAEKvM?usp=sharing`,
                                            icon: <BsCode />
                                        }
                                    },
                                    {
                                        button: {
                                            title: `3-3-Exercise`,
                                            url: `https://colab.research.google.com/drive/1sX2SR_O_bsH1gvo9LSIuFOGYHVB7Vwbt?usp=sharing`,
                                            icon: <BsCode />
                                        }
                                    },
                                    {
                                        header: `QUIZ`,
                                    },
                                    {
                                        text: `Question 1 — Why does sequence make a large difference when determining semantics of language?`,
                                        subtext: `Because the order in which words appear dictate their impact on the meaning of the sentence`
                                    },
                                    {
                                        text: `Question 2 — How do Recurrent Neural Networks help you understand the impact of sequence on meaning?`,
                                        subtext: `They carry meaning from one cell to the next`
                                    },
                                    {
                                        text: `Question 3 — How does an LSTM help understand meaning when words that qualify each other aren't necessarily beside each other in a sentence?`,
                                        subtext: `Values from earlier words can be carried to later ones via a cell state`
                                    },
                                    {
                                        text: `Question 4 — What keras layer type allows LSTMs to look forward and backward in a sentence?`,
                                        subtext: `Bidirectional`
                                    },
                                    {
                                        text: `Question 5What's the output shape of a bidirectional LSTM layer with 64 units?`,
                                        subtext: `(None, 128)`
                                    },
                                    {
                                        text: `Question 6 — When stacking LSTMs, how do you instruct an LSTM to feed the next one in the sequence?`,
                                        subtext: `Ensure that return_sequences is set to True only on units that feed to another LSTM`
                                    },
                                    {
                                        text: `Question 7 — If a sentence has 120 tokens in it, and a Conv1D with 128 filters with a Kernal size of 5 is passed over it, what's the output shape?`,
                                        subtext: `(None, 116, 128)`
                                    },
                                    {
                                        text: `Question 8What's the best way to avoid overfitting in NLP datasets?`,
                                        subtext: `None of the above (Use LSTMs, Use GRUs, Use Conv1D)`
                                    },
                                ]
                            },
                            {
                                title: `Week 4 — Sequence Models and Literature`,
                                content: [
                                    {
                                        button: {
                                            title: `3-4-A`,
                                            url: `https://colab.research.google.com/drive/1e7MWfPOiXmN3qZAzUq0QM5COo5sVRh3V?usp=sharing`,
                                            icon: <BsCode />
                                        }
                                    },
                                    {
                                        button: {
                                            title: `Character-Based Text Generation with RNN`,
                                            url: `https://www.tensorflow.org/tutorials/text/text_generation`,
                                            icon: <BsCode />
                                        }
                                    },
                                    {
                                        button: {
                                            title: `3-4-B`,
                                            url: `https://colab.research.google.com/drive/1C2eVeXvBLkOFmVryLvdIHN6SdBUhjehp?usp=sharing`,
                                            icon: <BsCode />
                                        }
                                    },
                                    {
                                        button: {
                                            title: `3-4-Exercise`,
                                            url: `https://colab.research.google.com/drive/1EH6G3Vqitk15SsevGMJ7vy05DoVpweGe?usp=sharing`,
                                            icon: <BsCode />
                                        }
                                    },
                                    {
                                        button: {
                                            title: `3-4-Exercise Solution`,
                                            url: `https://colab.research.google.com/drive/1S5gyUSU_s40bITTnzi-_2gK0H6PPHHxA?usp=sharing`,
                                            icon: <BsCode />
                                        }
                                    },
                                    {
                                        header: `QUIZ`,
                                    },
                                    {
                                        text: `Question 1 — What is the name of the method used to tokenize a list of sentences?`,
                                        subtext: `fit_on_texts(sentences)`
                                    },
                                    {
                                        text: `Question 2 — If a sentence has 120 tokens in it, and a Conv1D with 128 filters with a Kernal size of 5 is passed over it, what's the output shape?`,
                                        subtext: `(None, 116, 128)`
                                    },
                                    {
                                        text: `Question 3 — What is the purpose of the embedding dimension?`,
                                        subtext: `It is the number of dimensions for the vector representing the word encoding`
                                    },
                                    {
                                        text: `Question 4 — IMDB Reviews are either positive or negative. What type of loss function should be used in this scenario?`,
                                        subtext: `Binary crossentropy`
                                    },
                                    {
                                        text: `Question 5 — If you have a number of sequences of different lengths, how do you ensure that they are understood when fed into a neural network?`,
                                        subtext: `Use the pad_sequences object from the tensorflow.keras.preprocessing.sequence namespace`
                                    },
                                    {
                                        text: `Question 6 — When predicting words to generate poetry, the more words predicted the more likely it will end up gibberish. Why?`,
                                        subtext: `Because the probability that each word matches an existing phrase goes down the more words you create`
                                    },
                                    {
                                        text: `Question 7 — What is a major drawback of word-based training for text generation instead of character-based generation?`,
                                        subtext: `Because there are far more words in a typical corpus than characters, it is much more memory intensive`
                                    },
                                    {
                                        text: `Question 8 — How does an LSTM help understand meaning when words that qualify each other aren't necessarily beside each other in a sentence?`,
                                        subtext: `Values from earlier words can be carried to later ones via a cell state`
                                    },
                                ]
                            }
                        ]
                    },
                    {
                        text: `Course #4 — Sequences, Time Series, & Prediction`,
                        url: `https://www.coursera.org/learn/tensorflow-sequences-time-series-and-prediction`
                    },
                    {
                        accordions: [
                            {
                                title: `Course #4 — Certificate of Completion`,
                                content: [
                                    {
                                        image: require('../../assets/images/portfolio/posts/tensorflow/Time-Series-Predictions-Tensorflow-in-Practice-Specialization-Certificate-of-Completion-Ryan-Zernach-2340x1812x0x0x2340x1812x1595191304.png'),
                                        caption: 'Completed in July 2020'
                                    }
                                ]
                            },
                            {
                                title: `Week 1 — Sequences & Prediction`,
                                content: [
                                    {
                                        button: {
                                            title: `4-1-A`,
                                            url: `https://colab.research.google.com/drive/1FbCUY5-brxB6lGRLPU36hxlS1HiKeMoX?usp=sharing`,
                                            icon: <BsCode />
                                        }
                                    },
                                    {
                                        button: {
                                            title: `4-1-B`,
                                            url: `https://colab.research.google.com/drive/1m6j7xHmhigw9Re4z-o9ctawG1xVW0c88?usp=sharing`,
                                            icon: <BsCode />
                                        }
                                    },
                                    {
                                        button: {
                                            title: `4-1-Exercise`,
                                            url: `https://colab.research.google.com/drive/1umi7rfG2e-T3ivcDw2ZJNcDw4UuYdK-J?usp=sharing`,
                                            icon: <BsCode />
                                        }
                                    },
                                    {
                                        header: `QUIZ`,
                                    },
                                    {
                                        text: `Question 1 — What is an example of a Univariate time series?`,
                                        subtext: `Hour by hour temperature`
                                    },
                                    {
                                        text: `Question 2 — What is an example of a Multivariate time series?`,
                                        subtext: `Hour by hour weather`
                                    },
                                    {
                                        text: `Question 3 — What is imputed data?`,
                                        subtext: `A projection of unknown (usually past or missing)`
                                    },
                                    {
                                        text: `Question 4 — True or false? A sound wave is a good example of time series data.`,
                                        subtext: `True`
                                    },
                                    {
                                        text: `Question 5 — What is Seasonality?`,
                                        subtext: `A regular change in shape of the data`
                                    },
                                    {
                                        text: `Question 6 — What is a trend?`,
                                        subtext: `An overall direction for data regardless of direction`
                                    },
                                    {
                                        text: `Question 7 — In the context of time series, what is noise?`,
                                        subtext: `Unpredictable changes in time series data `
                                    },
                                    {
                                        text: `Question 8 — What is autocorrelation?`,
                                        subtext: `Data that follows a predictable shape, even if the scale is different `
                                    },
                                    {
                                        text: `Question 9 — What is a non-stationary time series?`,
                                        subtext: `One that has a disruptive event breaking trend and seasonality `
                                    },
                                ]
                            },
                            {
                                title: `Week 2 — Deep Neural Networks for Time Series`,
                                content: [
                                    {
                                        button: {
                                            title: `4-2-A`,
                                            url: `https://colab.research.google.com/drive/1Rs0m6y-QYMpmgEB3lDrIsCQAjWXY0QXk?usp=sharing`,
                                            icon: <BsCode />
                                        }
                                    },
                                    {
                                        button: {
                                            title: `4-2-B`,
                                            url: `https://colab.research.google.com/drive/1Lnckeycn4VGrang7WqwAiruexlixE1RC?usp=sharing`,
                                            icon: <BsCode />
                                        }
                                    },
                                    {
                                        button: {
                                            title: `4-2-C`,
                                            url: `https://colab.research.google.com/drive/1xcOaUhgiAa9MnBG-RpvR-YvYVbuIjZvu?usp=sharing`,
                                            icon: <BsCode />
                                        }
                                    },
                                    {
                                        button: {
                                            title: `4-2-Exercise`,
                                            url: `https://colab.research.google.com/drive/1xcOaUhgiAa9MnBG-RpvR-YvYVbuIjZvu?usp=sharing`,
                                            icon: <BsCode />
                                        }
                                    },
                                    {
                                        header: `QUIZ`,
                                    },
                                    {
                                        text: `Question 1 — What is a windowed dataset?`,
                                        subtext: `A fixed-size subset of a time series`
                                    },
                                    {
                                        text: `Question 2 — What does 'drop_remainder=true' do?`,
                                        subtext: `It ensures that all rows in the data window are the same length by cropping data`
                                    },
                                    {
                                        text: `Question 3 — What's the correct line of code to split an n column window into n-1 columns for features and 1 column for a label?`,
                                        subtext: `dataset = dataset.map(lambda window: (window[:-1], window[-1:]))`
                                    },
                                    {
                                        text: `Question 4 — What does MSE stand for?`,
                                        subtext: `Mean Squared Error`
                                    },
                                    {
                                        text: `Question 5 — What does MAE stand for?`,
                                        subtext: `Mean Absolute Error`
                                    },
                                    {
                                        text: `Question 6 — If time values are in time[], series values are in series[] and we want to split the series into training and validation at time 1000, what is the correct code?`,
                                        subtext: `time_train = time[:split_time]; x_train = series[:split_time]; time_valid = time[split_time:]; x_valid = series[split_time:]`
                                    },
                                    {
                                        text: `Question 7 — If you want to inspect the learned parameters in a layer after training, what's a good technique to use?`,
                                        subtext: `Assign a variable to the layer and add it to the model using that variable. Inspect its properties after trainingDecompile the model and inspect the parameter set for that layer`
                                    },
                                    {
                                        text: `Question 8 — How do you set the learning rate of the SGD optimizer? `,
                                        subtext: `Use the lr property`
                                    },
                                    {
                                        text: `Question 9 — If you want to amend the learning rate of the optimizer on the fly, after each epoch, what do you do?`,
                                        subtext: `Use a LearningRateScheduler object in the callbacks namespace and assign that to the callback`
                                    },
                                ]
                            },
                            {
                                title: `Week 3 — Recurrent Neural Networks for Time Series`,
                                content: [
                                    {
                                        button: {
                                            title: `4-3-A`,
                                            url: `https://colab.research.google.com/drive/1Hl4Ty9uyXqrLl2LhFn922OO0Z439hh1u?usp=sharing`,
                                            icon: <BsCode />
                                        }
                                    },
                                    {
                                        button: {
                                            title: `4-3-B`,
                                            url: `https://colab.research.google.com/drive/17tN6hhC7P9US9YMf83dxOjYW2v_UeUkm?usp=sharing`,
                                            icon: <BsCode />
                                        }
                                    },
                                    {
                                        button: {
                                            title: `4-3-Exercise`,
                                            url: `https://colab.research.google.com/drive/17d_60z3Slmn29iZ2p4p_XTtIah8rwu7z?usp=sharing`,
                                            icon: <BsCode />
                                        }
                                    },
                                    {
                                        header: `QUIZ`,
                                    },
                                    {
                                        text: `Question 1 — If X is the standard notation for the input to an RNN, what are the standard notations for the outputs?`,
                                        subtext: `Y(hat) and H`
                                    },
                                    {
                                        text: `Question 2 — What is a sequence to vector if an RNN has 30 cells numbered 0 to 29`,
                                        subtext: `The Y(hat) for the last cell`
                                    },
                                    {
                                        text: `Question 3 — What does a Lambda layer in a neural network do?`,
                                        subtext: `Allows you to execute arbitrary code while training`
                                    },
                                    {
                                        text: `Question 4 — What does the axis parameter of tf.expand_dims do?`,
                                        subtext: `Defines the dimension index at which you will expand the shape of the tensor`
                                    },
                                    {
                                        text: `Question 5 — A new loss function was introduced in this module, named after a famous statistician. What is it called?`,
                                        subtext: `Huber loss`
                                    },
                                    {
                                        text: `Question 6 — What's the primary difference between a simple RNN and an LSTM?`,
                                        subtext: `In addition to the H output, LSTMs have a cell state that runs across all cells`
                                    },
                                    {
                                        text: `Question 7 — If you want to clear out all temporary variables that tensorflow might have from previous sessions, what code do you run?`,
                                        subtext: `tf.keras.backend.clear_session()`
                                    },
                                    {
                                        text: `Question 8 — What happens if you define a neural network with these two layers? tf.keras.layers.Bidirectional(tf.keras.layers.LSTM(32)), tf.keras.layers.Bidirectional(tf.keras.layers.LSTM(32)), tf.keras.layers.Dense(1)`,
                                        subtext: `Your model will fail because you need return_sequences=True after the first LSTM layer`
                                    },
                                ]
                            },
                            {
                                title: `Week 4 — Real World Time Series Data`,
                                content: [
                                    {
                                        button: {
                                            title: `4-4-A`,
                                            url: `https://colab.research.google.com/drive/1-4qOU9tlqLRmHvRuvpd2t3vWDuxujBpt?usp=sharing`,
                                            icon: <BsCode />
                                        }
                                    },
                                    {
                                        button: {
                                            title: `4-4-B`,
                                            url: `https://colab.research.google.com/drive/1y_9YM5NrEPHhur00VvSebcFVm7v_NDQT?usp=sharing`,
                                            icon: <BsCode />
                                        }
                                    },
                                    {
                                        button: {
                                            title: `4-4-C`,
                                            url: `https://colab.research.google.com/drive/1AODRtRMftMSC8MTrFXFiNAw2zVZQg2Ze?usp=sharing`,
                                            icon: <BsCode />
                                        }
                                    },
                                    {
                                        button: {
                                            title: `4-4-Exercise`,
                                            url: `https://colab.research.google.com/drive/192ttJ0it8YKkvscOFEkTTMfNATV2NAKC?usp=sharing`,
                                            icon: <BsCode />
                                        }
                                    },
                                    {
                                        button: {
                                            title: `4-4-Exercise Solution`,
                                            url: `https://colab.research.google.com/drive/1k7_DGUPTGuhBq0T6oL2ycVKIQFUDxR-W?usp=sharing`,
                                            icon: <BsCode />
                                        }
                                    },
                                    {
                                        header: `QUIZ`,
                                    },
                                    {
                                        text: `Question 1 — 1.Question 1How do you add a 1 dimensional convolution to your model for predicting time series data?`,
                                        subtext: `Use a Conv1D layer type`
                                    },
                                    {
                                        text: `Question 2 — What's the input shape for a univariate time series to a Conv1D?`,
                                        subtext: `[None, 1]`
                                    },
                                    {
                                        text: `Question 3 — You used a sunspots dataset that was stored in CSV. What's the name of the Python library used to read CSVs?`,
                                        subtext: `CSV`
                                    },
                                    {
                                        text: `Question 4 — If your CSV file has a header that you don't want to read into your dataset, what do you execute before iterating through the file using a 'reader' object?`,
                                        subtext: `next(reader)`
                                    },
                                    {
                                        text: `Question 5 — When you read a row from a reader and want to cast column 2 to another data type, for example, a float, what's the correct syntax?`,
                                        subtext: `float(row[2])`
                                    },
                                    {
                                        text: `Question 6 — What was the sunspot seasonality?`,
                                        subtext: `11 or 22 years depending on who you ask`
                                    },
                                    {
                                        text: `Question 7 — After studying this course, what neural network type do you think is best for predicting time series like our sunspots dataset?`,
                                        subtext: `A combination of all of the above (DNN, RNN / LSTM, Convolutions)`
                                    },
                                    {
                                        text: `Question 8 — Why is MAE a good analytic for measuring accuracy of predictions for time series?`,
                                        subtext: `It doesn't heavily punish larger errors like square errors do`
                                    },
                                ]
                            }
                        ]
                    },
                ]
            },
            // MIAMI BEACH ATTRACTIONS
            {
                route: 'miami-beach-attractions',
                title: '🏝️ Miami & Miami Beach Attractions',
                highlightColor: colors.blue,
                subtitle: 'Hope you enjoy the list of suggestions below! If you have an idea for something to add to the list, then please send me an email (Ryan@Zernach.com)',
                image: require('../../assets/images/portfolio/posts/South_Beach_Miami_Beach_Attractions_Travel_Exciting_Fun_Exploring_Journey_Tropical.jpg'),
                links: [
                    {
                        title: 'Official Miami Beach Website',
                        url: 'https://www.miamibeachfl.gov/',
                        icon: <BsBoxArrowInUpRight />
                    },
                    {
                        title: 'Footer Photo Credits',
                        url: 'https://www.istockphoto.com/photo/sunset-in-miami-south-beach-florida-usa-gm1359725053-432976877',
                        icon: <BsBoxArrowInUpRight />
                    }
                ],
                body: [
                    {
                        accordions: [
                            {
                                title: 'South Beach',
                                content: [
                                    {
                                        title: 'South Pointe Park Pier',
                                        url: 'https://maps.app.goo.gl/nnzyAyRNh6GVrMV8A?g_st=ic',
                                        details: [
                                            `The southernmost point of South Beach, this pier has a great view and beautiful surroundings with lively/athletics culture`,
                                            `The beach near South Pointe is the best beach in all of Miami because you can walk out very far into the ocean and still be knee-deep.`,
                                            `They regularly have tractors excavate the seaweed onshore.`,
                                            `Go to the South Pointe Park Lighthouse anytime from ~4pm-10pm every Sunday night for drum circles, salsa dancing, and good vibes with many other people while enjoying the cruise ships as they drive past.`,
                                        ]
                                    },
                                    {
                                        title: 'Lumus Park / Miami Beach Beachwalk',
                                        url: 'https://maps.app.goo.gl/LSkWEuQeqqKVQVQd7?g_st=ic',
                                        details: [
                                            `Lumus Park is a must-see, long, skinny park that runs from 5th Street to 15th Street — the most vibrant & lively area in all of South Beach`,
                                            `However, the Beachwalk itself goes for about 10 miles, from South Pointe Park Pier all the way up to North Miami Beach / Bal Harbour`,
                                        ]
                                    },
                                    {
                                        title: 'Biscayne Bay Path',
                                        url: 'https://maps.app.goo.gl/sYfRQ6adcE6VaFJD9?g_st=ic',
                                        details: [
                                            `Spanning ~4 blocks on the West side of the island, please do walk this path at sunset.`,
                                            `Technically, bayside is private property, but they leave this path open to the public until dusk.`,
                                        ]
                                    },
                                    {
                                        title: 'Lincoln Road Shopping Mall',
                                        url: 'https://maps.app.goo.gl/9pw7eUrvv3RyBmFn8?g_st=ic',
                                        details: [
                                            `Outdoor shopping mall, restaurants, bars, and more that stretches from Alton Road to Washington Avenue`,
                                            `Walk beyond to the west (Biscayne Bay Path) or walk beyond to the east (Miami Beach Beachwalk)`,
                                        ]
                                    },
                                    {
                                        title: 'Miami Beach Paddleboard',
                                        url: 'https://maps.app.goo.gl/pSgme4NBVKm9MLj37?g_st=ic',
                                        details: [
                                            `South Beach paddleboarding on calm bayside waters up & down the waterways between beautiful homes`,
                                        ]
                                    },
                                    {
                                        title: 'ARTECHOUSE',
                                        url: 'https://maps.app.goo.gl/hPFvwKep48VFxwcY8?g_st=ic',
                                        details: [
                                            `Art/Tech Exhibit (locations in South Beach, Washington DC, and NYC)`,
                                        ]
                                    },
                                    {
                                        title: 'Moxy',
                                        url: 'https://maps.app.goo.gl/5KfjbHdc8q4B8jdD9?g_st=ic',
                                        details: [
                                            `South Beach Hotel with Rooftop Wading Pool & Two-Sided View`,
                                        ]
                                    },
                                    {
                                        title: 'Sky Yard',
                                        url: 'https://maps.app.goo.gl/uEpetmfMf6DCmLCi8?g_st=ic',
                                        details: [
                                            `South Beach Rooftop Bar with a view that faces the entire city of South Beach`,
                                        ]
                                    },
                                    {
                                        title: 'Year-Round Ice-Skating Rink',
                                        url: 'https://www.miamibeachfl.gov/city-hall/parks-and-recreation/ice-skating/',
                                        details: [
                                            `Scott Rakow Youth Center has public skating sessions a few hours a day every day of the week!`,
                                        ]
                                    },
                                ]
                            },
                            {
                                title: 'Downtown: Brickell & Wynwood',
                                content: [
                                    {
                                        title: 'Thriller Miami',
                                        url: 'https://www.thrillermiami.com/',
                                        details: [
                                            `Speed Boat Tour Starting in Bayside Marketplace in downtown Miami (Brickell)`,
                                            `Funny tour guides, great views, and a fun time on the water like a roller coaster`,
                                        ]
                                    },
                                    {
                                        title: 'Bayside Marketplace',
                                        url: 'https://www.baysidemarketplace.com/visit/360-virtual-map',
                                        details: [
                                            `Ferris wheel, shopping, restaurants, and bars on the water`,
                                            `Bayside is a marina, so there are plenty of options for boat/jetski tours or simply boat-watching`,
                                        ]
                                    },
                                    {
                                        title: 'Miami Design District',
                                        url: 'https://maps.app.goo.gl/gCRm6VL3NGt9VjL68?g_st=ic',
                                        details: [
                                            `Spectacularly artsy, high-end outdoor, sub-tropical shopping mall super-block that's decked out in art 360-degrees`,
                                        ]
                                    },
                                    {
                                        title: 'Adrienne Arsht Performing Arts Center',
                                        url: 'https://maps.app.goo.gl/9XmzaTCc684KiTpz9?g_st=ic',
                                        details: [
                                            `Downtown performing arts center — check their website in link above for showtimes`,
                                        ]
                                    },
                                    {
                                        title: 'Frost Museum of Science',
                                        url: 'https://maps.app.goo.gl/9XmzaTCc684KiTpz9?g_st=ic',
                                        details: [
                                            `Science museum with planetarium`,
                                        ]
                                    },
                                ]
                            },
                            {
                                title: 'Virginia Key & Key Biscayne',
                                content: [
                                    {
                                        title: 'Virginia Key North Point Trails',
                                        url: 'https://maps.app.goo.gl/VkNFQCCrgW3HnQTr5?g_st=com.tinyspeck.chatlyio.share',
                                        details: [
                                            `Mountain biking trails through the mangroves; this particular part of the island is the highest terrain in Miami (which still is not very high elevation)`,
                                            `Walk along the beach with unique perspectives of Fisher Island, Port Miami, & downtown Miami in the distance`,
                                        ]
                                    },
                                    {
                                        title: 'Cape Florida Lighthouse',
                                        url: 'https://maps.app.goo.gl/kBEMbGHPH5DhhefM7?g_st=ic',
                                        details: [
                                            `Miles of biking/walking trails with lighthouse, go fishing, or scuba diving for lobster at the right time of year`,
                                        ]
                                    },
                                    {
                                        title: 'Miami Seaquarium',
                                        url: 'https://goo.gl/maps/qRsxCwTs8jhwCSkN6',
                                        details: [
                                            `Lively complex hosting shows, sealife displays & kids' playground, plus dolphin rides & experiences.`,
                                        ]
                                    },
                                    {
                                        title: 'University of Miami: Oceanic, Marine, Atmospheric, & Earth Science',
                                        url: 'https://www.earth.miami.edu/',
                                        details: [
                                            `Some of the most interesting oceanic science research in the world is happening here at this beautiful campus of the University of Miami`,
                                            `It's definitely worth checking out if you enjoy exploring college campuses`
                                        ]
                                    },
                                ]
                            },
                            {
                                title: 'Metro Miami Area',
                                content: [
                                    {
                                        title: 'Fairchild Tropical Botanical Garden',
                                        url: 'https://maps.app.goo.gl/7xkmvkKE5QSZh7uAA?g_st=ic',
                                        details: [
                                            `Vast property filled with beautiful walking trails filled with gorgeous plants`,
                                            `During the “holiday season” (roughly November/December and beginning of January), go to their gardens after dark for spectacular lighting effects!`,
                                        ]
                                    },
                                    {
                                        title: 'Oleta Park',
                                        url: 'https://www.floridastateparks.org/OletaRiver',
                                        details: [
                                            `Unforgettable kayaking, canoeing, & paddleboarding through calm mangroves and beaches`,
                                            `Walking trails, biking trails, camping, & lodging are also available at the park`,
                                        ]
                                    },
                                    {
                                        title: 'Fruit & Spice Park',
                                        url: 'https://maps.app.goo.gl/qSutvb832JPfZZRo8?g_st=ic',
                                        details: [
                                            `Pay to access the walking trails with all-you-can-eat tropical fruits & nuts right off the trees (varieties vary at different times of year)`,
                                            `There are usually two tours during the day at two different times, but you can walk by yourselves if desired`
                                        ]
                                    },
                                ]
                            }
                        ]
                    }
                ],
            },
        ]
    }, [])

    return {
        blogPosts
    }
}