

import * as React from "react"
import "../../App.css"
import { Navbar } from "../navbar/navbar";
import { CustomCarousel as Carousel } from "../carousel/carousel";
import { colors } from '../../assets/constants/colors'

/**
 * Describe the new component here...
 */
export const Header = () => {

    return (
        <header className="vshapedBottomDivider">
            <div style={{ backgroundColor: '#00000099', paddingBottom: '30px' }}>
                <div className="flexed" style={{ justifyContent: 'space-evenly', alignItems: 'center' }}>
                    <div className={'headerSection'}>
                        <h1 style={{ color: colors.primary, fontSize: '1.6rem' }}>
                            {'ARCHLIFE'}
                            <span style={{ color: '#ffffff99' }}>
                                {' INDUSTRIES'}
                            </span>
                        </h1>
                        <h2 style={{ color: '#ffffff75', fontSize:'1rem', fontFamily: "Regular" }}>
                            {'IMPROVING LIVES WITH SOFTWARE'}
                        </h2>
                    </div>
                    <div className={'headerSection'}>
                        <img src={require('../../assets/images/home/archlife_indsutries_inc_software_solutions.png')} className="app-icon" alt="Ryan_Zernach_2023_Mobile_App_Developer_React_Native_Remote_STEM_Tech_Network_iOS_Android_Miami_Beach" style={{}} />
                    </div>
                    <div className={'headerSection'}>
                        <Carousel />
                    </div>
                </div>
                <Navbar />
            </div>
        </header>
    );
};
