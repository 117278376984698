import { useMemo, useContext } from 'react';
import { WebContext } from '../../contexts/web/web-context-provider';
import { useLocation } from 'react-router-dom';
import { useBlogPosts } from '../../scripts/use-blog-posts/use-blog-posts';

export const useBlogPostFunctions = () => {

    const { blogPosts } = useBlogPosts()

    const { onPressOpenLinkInNewTab } = useContext(WebContext)
    const location = useLocation()

    const nameOfCurrentRoute = useMemo(() => {
        const fullPath = location?.pathname?.split('/portfolio/')
        const portfolioPath = fullPath?.[1]?.toLowerCase()
        const portfolioPathWithoutTrailingSlash = portfolioPath?.replace(/\/$/, "")
        return portfolioPathWithoutTrailingSlash
    }, [location.pathname])

    const blogPost = useMemo(() => {
        return blogPosts?.find((blogPost) => blogPost.route === nameOfCurrentRoute)
    }, [blogPosts, nameOfCurrentRoute])

    return {
        onPressOpenLinkInNewTab,
        blogPost,
    }
}