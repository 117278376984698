import { BrowserRouter, Routes, Route } from "react-router-dom"
import { WebContextProvider } from "./contexts/web/web-context-provider"
import { Portfolio } from "./screens/portfolio/portfolio"
import { BlogPost } from "./screens/blog-post/blog-post"
import { Home } from "./screens/home/home"
import * as React from "react"
import "./App.css"

const App = () => {
  return (
    <WebContextProvider>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/portfolio" element={<Portfolio />} />
          <Route path="/portfolio/*" element={<BlogPost />} />
        </Routes>
      </BrowserRouter>
    </WebContextProvider>
  )
}

export default App
