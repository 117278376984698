import React, { useState, useCallback, useMemo } from 'react';
import { FaChevronDown, FaChevronUp } from 'react-icons/fa';
import './accordion.css';

export const Accordion = ({ title, textStyle, highlightColor, children, style }) => {
    const [isOpen, setIsOpen] = useState(false);

    const [isHovering, setIsHovering] = useState(false);

    const handleMouseEnter = () => {
        setIsHovering(true);
    };

    const handleMouseLeave = () => {
        setIsHovering(false);
    };

    const toggleAccordion = useCallback(() => {
        setIsOpen(!isOpen);
    }, [setIsOpen, isOpen])

    const styles = useMemo(() => {
        return {
            accordionHeader: {
                backgroundColor: (isHovering || isOpen) ? `${highlightColor}50` : '#1c1c1c',
                // borderBottom: '1px solid #DDDDDD',
                padding: '10px 15px',
                cursor: 'pointer',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                transition: 'background-color 0.5s',
                borderRadius: 4,
            },
            accordionBody: {
                backgroundColor: '#1c1c1c',
            },
            accordionInner: {
                backgroundColor: `${highlightColor}25`,
                border: '1px solid #DDDDDD',
                borderRadius: 4,
                ...style
            },
        }
    }, [isHovering, isOpen, highlightColor, style])

    return (
        <div style={styles.accordionBody}>
            <div
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
                style={styles.accordionInner}
            >
                <div className='accordion-header' onClick={toggleAccordion} style={styles.accordionHeader}>
                    <h3 style={{ textAlign: 'left', color: highlightColor, ...textStyle }}>{title}</h3>
                    {isOpen ? <FaChevronUp /> : <FaChevronDown />}
                </div>
                {isOpen && (
                    <>
                        {children}
                    </>
                )}
            </div>
        </div>
    );
}

